import * as actionTypes from "../../constants/actionTypes";

export const initialState = {
	applications: null,
	error: false,
	modalStatus: false,
	loading: false,
	accessKey: {},
	checkServerApiKeyMessage: '' 	
}

export const caApplicationReducer = (state = initialState, action) => {
	switch(action.type){
		case actionTypes.GET_CA_APPLICATIONS_SUCCESS:
			return{
				...state,
				applications: action.applications
			};
		case actionTypes.CHECK_SERVER_API_KEY_SUCCESS:
			return {
				...state,       
				checkServerApiKeyMessage: action.apiKey,   
			}
		case actionTypes.CREATE_ACCESS_KEY_SUCCESS:
			return{
				...state,
				accessKey: action.accessKey.data
			}
		case actionTypes.LOADING_STATUS:
			return {
				...state,       
				loading: action.loading,   
			}
		case actionTypes.RESET_SERVER_API_KEY_MESSAGE:
			return {
				...state,       
				checkServerApiKeyMessage: '',   
			}
		case actionTypes.SET_APPLICATION_MODAL:
			return{
				...state,
				modalStatus: action.status
			}
		default:
			return state;
	}
}

export default caApplicationReducer;