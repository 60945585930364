import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import * as appConstant from './constants/appConstant';
import Dashboard from './containers/Dashboard';
import Application from './containers/Application';
import CAApplication from './containers/CAApplication';
import FSApplication from './containers/FSApplication';
import Requests from './containers/Requests';
import CARequests from './containers/CARequests';
import PageNotFound from './containers/PageNotFound';
import PageUnauthorized from './containers/PageUnauthorized';
import LayoutAADContainer from './components/LayoutAADContainer';
import 'react-toastify/dist/ReactToastify.css';
import CAEmailTemplate from './containers/CAEmailTemplate';
import ManageUsers from './containers/ManageUsers';
import Loading from "./components/Loading";
//import ManageRoles from './containers/ManageRoles';
// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/CustomNavigationClient";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LoginAAD from './components/LoginAAD';

const pageRoutes = [
  {
    path: appConstant.ROUTE_APPLICATION,
    container: Application,
    tab: 'Application'
  },
  {
    path: appConstant.ROUTE_CAAPPLICATION,
    container: CAApplication,
    tab: 'CA Application'
  },
  // {
  //   path: appConstant.ROUTE_FSAPPLICATION,
  //   container: FSApplication,
  //   tab: 'FS Application'
  // },
  {
    path: appConstant.ROUTE_APPLICATION_PAGE,
    container: () => <Redirect replace to="/portal/application" />,
  },
  {
    path: appConstant.ROUTE_REQUESTS,
    container: Requests,
  },
  {
    path: appConstant.ROUTE_CA_REQUESTS,
    container: CARequests,
  },
  {
    path: appConstant.ROUTE_CA_EMAIL_TEMPLATE,
    container: CAEmailTemplate,
  },
  {
    path: appConstant.ROUTE_MANAGE_USERS,
    container: ManageUsers,
  },
  // {
  //   path: appConstant.ROUTE_MANAGE_ROLES,
  //   container: ManageRoles,
  // },
  {
    path: appConstant.ROUTE_DASHBOARD,
    container: Dashboard,
  }
];

const mapStateToProps = state => {
  return {
    loading: state.dashboard.loading
  }
}

function App({ pca, ...props }) {
  let isNsAdmin = false;
  let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
  let currentUserRole = localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) : [];
  let loading = true;
  if (userInfo.role) {
    if (typeof (userInfo.role) === 'string') isNsAdmin = (userInfo.role === 'NSAdmin');
    if (typeof (userInfo.role) === 'object') isNsAdmin = userInfo.role.some(item => item === 'NSAdmin');
  }

  if(localStorage.getItem('userInfo')) {
    loading = false;
  }

  if (Array.isArray(currentUserRole) && currentUserRole.some(item => item === 'NSAdmin')) {
    isNsAdmin = true;
  }

  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  let routes = (
    <Switch>
      <Redirect exact from="/" to="/portal" />
      {pageRoutes.map(route => (
        <Route exact key={route.path} path={route.path} component={route.container} />
      ))}
      <Route render={() => <PageNotFound />} />
    </Switch>
  );

  if (!isNsAdmin) {
    routes = (
      <Switch>
        <Redirect exact from="/" to="/401" />
        <Route render={() => <PageUnauthorized />} />
      </Switch>
    )
  }

  const onRefrestPage = () => {
    let userInfoCheck = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    let currentUserRoleCheck = localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) : null;
    if (userInfoCheck !== null) {
      document.location.href = window.location.protocol + '//' + window.location.host;
    }
  }

  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        {loading ? (<Loading />) : null}
        <LayoutAADContainer onRefrestPage={() => onRefrestPage()} pageRoutes={pageRoutes} routes={routes}></LayoutAADContainer>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginAAD></LoginAAD>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default connect(mapStateToProps, null)(App);
