const configurationLocal = {
  client_id: "3a1e688dc48540b2a454d08fa07fad0b",
  clientUri: "https://idsvc.petronas.com/",
  redirect_uri: process.env.REACT_APP_BASE_URL,
  response_type: "id_token token",
  post_logout_redirect_uri: process.env.REACT_APP_BASE_URL,
  scope: "openid profile mypetronasapi myfileapi mymemoapi myminutesapi notificationserviceapi",
  authority: "https://idsvc.petronas.com",
  silent_redirect_uri: process.env.REACT_APP_BASE_URL,
  automaticSilentRenew: true,
  loadUserInfo: true,
  triggerAuthFlow: true,
  unauthorizeRoute: "home",
  redirectCurrentLink: true,
  baseUrlApi: process.env.REACT_APP_API_URL,
  baseUrlFSApi: process.env.REACT_APP_FILESERVICE_BASE_URL
  //baseUrlApi: "https://staging.notificationservice.petronas.com/api"
  // baseUrlApi: "https://localhost:5011/api",
};

const configurationPipeline = {
  client_id: "IDSVC_CLIENT_ID",
  clientUri: "https://idsvc.petronas.com/",
  redirect_uri: "NS_BASE_PORTAL_URL",
  response_type: "id_token token",
  post_logout_redirect_uri: "NS_BASE_PORTAL_URL",
  scope: "openid profile mypetronasapi myfileapi mymemoapi myminutesapi notificationserviceapi",
  authority: "https://idsvc.petronas.com",
  silent_redirect_uri: "NS_BASE_PORTAL_URL",
  automaticSilentRenew: true,
  loadUserInfo: true,
  triggerAuthFlow: true,
  unauthorizeRoute: "home",
  redirectCurrentLink: true,
  baseUrlApi: "NS_BASE_API_URL",
  baseUrlFSApi: "FILESERVICE_BASE_URL"
};
export const configuration =  process.env.REACT_APP_STATE === 'pipeline' ? configurationPipeline : configurationLocal;

