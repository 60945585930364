import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/application";
import Wrapper from "./Wrapper";

function Application(props) { 
  
  

  return (
    <React.Fragment>
      <Wrapper {...props} />
    </React.Fragment>
  );
}


const mapStateToProps = state => {
  return {
      applicationsData: state.application.applications,
      modalStatus: state.application.modalStatus,
      mailConfigData: state.mailConfig.mailConfigs,
      accessKey: state.application.accessKey,
      checkServerApiKeyMessage: state.application.checkServerApiKeyMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {      
      onInitApplications: (params) => dispatch(actions.fetchApplications(params)),
      onUpdateApplication: (params) => dispatch(actions.updateApplication(params)),
      onDeleteApplication: (id) => dispatch(actions.deleteApplication(id)),
      onCreateApplication: (params) => dispatch(actions.createApplication(params)),
      onCreateUpdateMailConfig: (id, params) => dispatch(actions.createAndUpdateMailConfig(id, params)),
      onDeleteMailConfig: (id, params) => dispatch(actions.deleteMailConfig(id, params)),
      onFetchMailConfig: (id) => dispatch(actions.fecthMailConfig(id)),
      onCreateAccessKey: (params) => dispatch(actions.createAccessKey(params)),
      onUpdateAccessKey: (params) => dispatch(actions.updateAccessKey(params)),
      onDeleteAccessKey: (params) => dispatch(actions.deleteAccessKey(params)),
      onCheckApiKey: (params) => dispatch(actions.checkApiKey(params)),
      onResetServerApiKeyMessage: () => dispatch(actions.resetServerApiKeyMessage())
      
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);
