/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Your name
 * @ Modify Time: 2019-11-16 11:32:10
 * @ Description: Layout component
 */

import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { getInfroFromTokenAAD } from '../../utils/GetInfroFromTokenAAD';
import { loginRequest } from "../../constants/aadConfig";
import { ToastContainer } from 'react-toastify';
import Layout from '../../hoc/Layout/Layout';
import Loading from '../Loading';
import jwt_decode from "jwt-decode";

function LayoutAADContainer(props) {
    let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(userInfo);

    function ProfileContent() {
        getInfroFromTokenAAD().then((res) => {
            setGraphData(res);
            props.onRefrestPage();
        })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                    getInfroFromTokenAAD().then((res) => {
                        setGraphData(res);
                        props.onRefrestPage();
                    })
                }
            });
    }

    useEffect(() => {
        let accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).access_token : null;
        if ((!graphData && inProgress === InteractionStatus.None) || accessToken == null || (jwt_decode(accessToken).exp < Date.now() / 1000)) {
            ProfileContent();
        }
    }, [inProgress, graphData, instance]);

    return (
        <>
            <Layout pageRoutes={props.pageRoutes}>{props.routes}</Layout>
            {props.loading ? (<Loading />) : null}
            <ToastContainer
                autoClose={2000}
                preventDuplicates={true}
                preventOpenDuplicates={true}
                limit={1}
            />
        </>
    );
}

export default LayoutAADContainer;
