import React, { useState, useEffect } from "react";
import AccessKeysRow from './AccessKeysRow';
import AccessKeyRowCreate from './AccessKeyRowCreate';

function AccessKeyList({ 
    applicationId, 
    accessKeysData,
    onCloseModal,
    ...props
  }) {
  const [dataList, setDataList] = useState(accessKeysData);
  const [loadingInline, isLoadingInline] = useState(false);
  

  const updateRowOnDataList = (data) => {
    let draft = dataList;
    draft.forEach(rowItem => {
      if (rowItem.key === data.key) {
        rowItem.description = data.description;
        rowItem.isActive = data.isActive;
      }      
    });
    setDataList(draft);
  }

  const deleteRowOnDataList = (key) => {  
    const draft = [...dataList];
    setDataList(draft.filter(item => item.key !== key))
  } 

  useEffect(() => {
    if(Object.keys(props.accessKey).length > 0) {
      setDataList(accessKeysData);
    }
  }, [props.accessKey])

  return (
    <React.Fragment>
      <div className="position-relative">
        <table className="table access-key-list-table">
          <thead>
            <tr>
              <th className="table__key-id-column">Key Id</th>
              <th className="table__description-column">Description</th>
              <th className="table__created-on-column">Created On</th>
              <th className="text-center table__status-column">Status</th>
              <th className="text-right table__actions-column"></th>
            </tr>
          </thead>
          <tbody>
            {dataList &&
              dataList.map((item) => (
                <AccessKeysRow 
                  onCloseModal={() => onCloseModal()}
                  key={item.key}
                  applicationId={applicationId}
                  dataRow={item}
                  isLastOne={dataList.length === 1}
                  onSetLoading={data => isLoadingInline(data)}
                  updateRowOnDataList={(row) => updateRowOnDataList(row)}
                  deleteRowOnDataList={ (id) => deleteRowOnDataList(id)}
                  {...props} 
                />
              ))}
              <AccessKeyRowCreate 
                onCloseModal={() => onCloseModal()}
                onSetLoading={data => isLoadingInline(data)}
                applicationId={applicationId}  
                {...props} 
              />
          </tbody>
        </table>  
        {loadingInline ? (<div className="loading__inline"></div>):null}
      </div>
    </React.Fragment>
  );
}

export default AccessKeyList;
