import React, { memo, useState } from "react";
import Loading from '../../../components/Loading';
import UserFilter from "../UserFilter";
import * as appConstant from "../../../constants/appConstant"
import Button from 'react-bootstrap/Button';
import PageHeader from "../../../components/PageHeader";
import Pagination from "react-js-pagination";
import UserDialog from "../UserDialog";
import UserList from "../UserList";
import UserModal from "../UserModal";
import services from "../../../services/manageUserService";
import servicesRole from "../../../services/manageRoleService";
import { toast } from "react-toastify";
import "./styles.scss";

function Wrapper(props) {

  const initState = {
    "id": null,
    "userId": null,
    "email": null,
    "roleId": '',
    "isActive": true,
  }

  let initMock = {
    "pageIndex": 0,
    "limits": appConstant.GLOBAL_PAGE_SIZE,
    "name": null,
  };

  let initListRole = [{id: '', name: 'None'}];

  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editData, setEditData] = useState(initState);
  const [modalMode, setModalMode] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [filterParamsData, setFilterParamsData] = useState(initMock);
  const [activePage, setActivePage] = useState(1);
  const [allRole, setAllRole] = useState(initListRole);

  const setFilter = (params) => {
    setFilterParamsData(params);
  }

  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const pageIndex = pageNumber - 1;
    const draft = filterParamsData;
    draft.pageIndex = pageIndex;
    props.onInitUser(draft)
  }

  const onEditData = (id) => {
    servicesRole.getAllRoles()
      .then((resRole) => {
        initListRole = initListRole.concat(resRole.data);
        setAllRole(initListRole);
        services.getUserById(id)
          .then((res) => {
            setEditData(res.data);
            setModalMode('EDIT');
            setEditModalStatus(true);
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      })
      .catch((errRole) => {
        toast.error(errRole.toString());
      });
  }

  const onUpdateData = async () => {
    await props.onUpdateUser(editData, initMock);
    setEditModalStatus(false);
  }

  const onOpenCreateModal = async () => {
    servicesRole.getAllRoles()
      .then((resRole) => {
        initListRole = initListRole.concat(resRole.data);
        setAllRole(initListRole);
      })
      .catch((errRole) => {
        toast.error(errRole.toString());
      });
    setEditData(initState);
    setModalMode('ADD');
    setEditModalStatus(true);
  }

  const onCreateData = async () => {
    await props.onCreateUser(editData, initMock);
    setEditModalStatus(false);
  }

  const openDeleteModal = (k) => {
    setIdDelete(k);
    setDialogStatus(true);
  }

  const onDeleteData = async (k) => {
    await props.onDeleteUser(k, initMock);
    setDialogStatus(false);
  }

  const renderTotalList = () => {
    let dataDetails = props.usersData.dataDetails ? props.usersData.dataDetails : [];
    return (
      <p style={{ display: 'block', fontSize: 14 }}>Showing: {(activePage - 1) * filterParamsData.limits} to {dataDetails.length > (activePage * filterParamsData.limits) ? activePage * filterParamsData.limits : (activePage - 1) * filterParamsData.limits + dataDetails.length} of first {props.usersData.totalItems} records (may have more)</p>
    );
  }

  return (
    <React.Fragment>
      <div className="page">
        <PageHeader pageTitle={"Manage Users"} />
        <div className="page__body">
          <div className="ca-application-header">
            <div className="mb-3 ca-application-item-header" id="ca-application-btn-add">
              <Button variant="primary" className="ml-3 mt-3 mb-0" onClick={() => { onOpenCreateModal() }}>Add New</Button>
            </div>
            <div className="mb-9 ca-application-item-header" id="ca-application-section-search">
              <UserFilter
                filterParamsData={filterParamsData}
                onSetActivePage={(pageId) => setActivePage(pageId)}
                setFilterParamsData={(params) => { setFilter(params) }} {...props}></UserFilter>
            </div>
          </div>
          <UserList
            {...props}
            filterParamsData={filterParamsData}
            onDelete={k => openDeleteModal(k)}
            onEdit={k => onEditData(k)}></UserList>
          {props.usersData ? (
            <div className="pagination-container" style={{ flexDirection: 'column' }}>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                itemsCountPerPage={filterParamsData.limits}
                totalItemsCount={props.usersData.totalItems ?? 0}
                onChange={pageNumber => onPageChange(pageNumber)}
              />
              {renderTotalList()}
            </div>
          ) : null}
          <UserModal
            {...props}
            modalShow={editModalStatus}
            modalData={editData}
            modalMode={modalMode}
            dataListRoles={allRole}
            onUpdateData={() => onUpdateData()}
            onCreateData={() => onCreateData()}
            onShowEditModal={(status) => setEditModalStatus(status)}
            onSetEditData={(data) => setEditData(data)}
          />
          <UserDialog
            id={idDelete}
            modalStatus={dialogStatus}
            onDeleteData={(k) => onDeleteData(k)}
            onUpdateModalStatus={status => setDialogStatus(status)}
          />
          {props.loading ? (<Loading />) : ("")}
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(Wrapper);
