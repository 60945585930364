import http from "../constants/axiosOrders";


const getDashboardData = (params) => {
	return http.get("/dashBoard", {
		"params": params
	});
};


export default {
	getDashboardData
}