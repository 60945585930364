import * as actionTypes from "../../constants/actionTypes";
import services from "../../services/requestsService";
import * as appContants from "../../constants/appConstant";
import { toast } from "react-toastify";
import {updateLoadingStatus} from './app';

export const fetchRequestsSuccess = (requests) => {
    return {
        type: actionTypes.GET_REQUESTS_SUCCESS,
        requests: requests
    };
}

export const fetchApplicationsSuccess = (applications) => {
    return {
      type: actionTypes.GET_APPLICATIONS_SUCCESS,
      applications: applications,
    };
  };

export const fetchRequestsFailure = (error) => {
    return {
        type: actionTypes.GET_REQUESTS_FAILURE,
        error: error
    };
}
export const fetchRequestSuccess = (request) => {
    return {
        type: actionTypes.GET_REQUEST_SUCCESS,
        request: request
    };
}

export const fetchRequestFailure = (error) => {
    return {
        type: actionTypes.GET_REQUEST_FAILURE,
        error: error
    };
}

export const resendEmailSuccess = () => {
    return {
        type: actionTypes.RESEND_EMAIL_SUCCESS
    };
}

export const resendEmailFailure = (error) => {
    return {
        type: actionTypes.RESEND_EMAIL_FAILURE,
        error: error
    };
}

export const fetchRequests = (params) => {
    params = {...params, timeZoneClient: Intl.DateTimeFormat().resolvedOptions().timeZone};
       return (dispatch) => {        
        dispatch(updateLoadingStatus(true));
        services.fetchRequests(params).then((response) => {
            dispatch(fetchRequestsSuccess(response.data));
            dispatch(updateLoadingStatus(false));
        }).catch(error => {
            // let message = error.response.data.response.message;
            // if (error.response.data.message)  {
            //     message = error.response.data.message;          
            // }
            toast.error(error);
        }).finally(() =>{
            // dispatch(updateLoadingStatus(false));
        });
    }
}

export const getRequestDetail = (id) => {
    return (dispatch) => {        
     dispatch(updateLoadingStatus(true));
     services.fetchRequestById(id).then((response) => {
         dispatch(fetchRequestSuccess(response.data));  
         dispatch(updateLoadingStatus(false));
     }).catch(error => {
        dispatch(fetchRequestFailure(error.response.message));  
        toast.error(error.response.message);
     }).finally(() =>{
         // dispatch(updateLoadingStatus(false));
     });
 }
}

export const resendEmail = ({requestId, params}) => {
    return (dispatch) => {        
     dispatch(updateLoadingStatus(true));
     services.resendEmail(requestId).then((response) => {
        dispatch(resendEmailSuccess());
        dispatch(fetchRequests(params));        
        dispatch(updateLoadingStatus(false));
     }).catch(error => {
        let message = error.response.data.response.message;
        if (error.response.data.message)  {
          message = error.response.data.message;          
        }
        toast.error(message);
     }).finally(() => {
     })
 }
}

export const fetchCARequests = (params) => {
    return (dispatch) => {        
     dispatch(updateLoadingStatus(true));
     services.fetchCARequests(params).then((response) => {
         dispatch(fetchRequestsSuccess(response.data));
         dispatch(updateLoadingStatus(false));
     }).catch(error => {
        //  let message = error.response ? error.response.data.response.message : error.response.data.status;
        //  if (error.response.data.message)  {
        //      message = error.response.data.message;          
        //  }
         toast.error(error);
     }).finally(() =>{
         // dispatch(updateLoadingStatus(false));
     });
 }
}

export const fetchCAApplications = (params) => {
    return (dispatch) => {
      dispatch(updateLoadingStatus(true));
      services
        .fetchCAApplications()
        .then((response) => {
          dispatch(fetchApplicationsSuccess(response.data)); 
        })
        .catch((error) => {        
          toast.error(error.toString());
        }).finally(() => {
          dispatch(updateLoadingStatus(false));
        });
    };
  };
