import * as actionTypes from "../../constants/actionTypes";
import * as appContants from "../../constants/appConstant";
import services from "../../services/ca-email-templateService";
import { updateLoadingStatus } from './app';
import { toast } from "react-toastify";

export const fetchEmailTemplatesSuccess = (emailTemplates) => {
  return {
    type: actionTypes.GET_CA_EMAIL_TEMPLATE_SUCCESS,
    emailTemplates: emailTemplates,
  };
};

export const fetchAllEmailTemplatesSuccess = (selectDataEmailTemplates) => {
  return {
    type: actionTypes.GET_CA_ALL_EMAIL_TEMPLATE_SUCCESS,
    selectDataEmailTemplates: selectDataEmailTemplates,
  };
};

export const fetchEmailTemplates = (params) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .getEmailTemplatePaging(params)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else {
          dispatch(fetchEmailTemplatesSuccess(response.data));
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchAllEmailTemplates = () => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .getAllEmailTemplate()
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else {
          dispatch(fetchAllEmailTemplatesSuccess(response.data));
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchUpdateEmailTemplate = (params, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .updateEmailTemplate(params)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else {
          toast.success(appContants.UPDATE_SUCCESS_MESSAGE, { toastId: "update-success" });
          dispatch(fetchEmailTemplates(filterParamsData));
        }
      })
      .catch((error) => {
        // toast.error(error.toString());
        let errorMsg = error.toString();
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchDeleteEmailTemplate = (id, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .deleteEmailTemplate(id)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else { 
          toast.success(appContants.DELETE_SUCCESS_MESSAGE, { toastId: "delete-success" });
          dispatch(fetchEmailTemplates(filterParamsData));
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchCreateApplication = (data, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .createEmailTemplate({ ...data })
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else { 
          toast.success(appContants.CREATE_SUCCESS_MESSAGE, { toastId: "create-success" });
          dispatch(fetchEmailTemplates(filterParamsData));
        }
      })
      .catch((error) => {
        let errorMsg = error.toString();
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      })
      .finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};


