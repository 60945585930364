import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./styles.scss";

function FSApplicationForm({
  modalData,
  onCreateData,
  onUpdateData,
  onSetEditData,
  ...props
}) {
  const [errors, setErrors] = useState([]);

  let formValid = false;

  const onUpdate = () => {
    checkValid();
    if (formValid) onUpdateData();
  }

  const onCreate = () => {
    checkValid();
    if (formValid) onCreateData();
  }

  const onSetInputValue = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      onSetEditData({
        ...modalData,
        [name]: value
      });
    }
  };

  const onSetCheckbox = (e) => {
    if (e && e.target) {
      const { name, checked } = e.target;
      onSetEditData({
        ...modalData,
        [name]: checked
      });
    }
  };

  const checkValid = () => {
    resetErrors();
    let tempErrors = [];
    formValid = true;

    if (!modalData.name || modalData.name == "") {
      tempErrors.push({
        "key": "name",
        "message": "Name is required"
      });
    }

    if (!modalData.description || modalData.description == "" || modalData.description.length < 10) {
      tempErrors.push({
        "key": "description",
        "message": "Description is required. MinLength is 10 characters"
      });
    }

    if (tempErrors.length > 0) formValid = false;
    setErrors(errors.concat(tempErrors));
  }

  const resetErrors = () => {
    while (errors.length > 0) {
      setErrors(errors.pop());
    }
  }

  useEffect(() => {

  }, []);

  const erroMessageRender = (nameField) => {
    return (
      <>
        {(errors.length > 0 && errors.some(item => item.key === nameField)) ? <small className="form-text text-danger">{errors.find(item => item.key === nameField).message}</small> : ""}
      </>
    );
  }

  //#region Form input
  const inputText = (nameDisplay, nameField, placeholderValue = null, isRequired, maxLength = null) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <input
            className="form-control"
            type="text"
            placeholder={placeholderValue ? 'Example: ' + placeholderValue : 'Input ' + nameDisplay}
            defaultValue={modalData[nameField]}
            onChange={(e) => onSetInputValue(e)}
            name={nameField}
            maxLength={maxLength ? maxLength : ""}
          />
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }

  const inputTextArea = (nameDisplay, nameField, placeholderValue = null, isRequired, maxLength = null) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <textarea
            className="form-control"
            placeholder={placeholderValue ? 'Example: ' + placeholderValue : 'Input ' + nameDisplay}
            defaultValue={modalData[nameField]}
            name={nameField}
            onChange={(e) => onSetInputValue(e)}
            maxLength={maxLength ? maxLength : ""}
          />
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }

  const inputCheckBox = (nameDisplay, nameField) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">{nameDisplay}</label>
        </Col>
        <Col xs={3} md={9}>
          <div className="form-label ca-form-label">
            <input
              type="checkbox"
              id={modalData.id}
              defaultChecked={modalData[nameField] ? true : false}
              onChange={(e) => onSetCheckbox(e)}
              name={nameField}
              className="form-label"
            />
          </div>
        </Col>
      </Row>
    );
  }


  //#endregion

  return (
    <form>
      <Container className="form-fields applicaion-form">
        {modalData.id ? (
          <Row className="ca-row-form">
            <Col xs={3} md={3} className="text-right">
              <label className="form-label ca-form-label">
                Application Id
              </label>
            </Col>
            <Col xs={9} md={9}>
              <div className="form-label text-muted ca-form-label">
                {modalData.id}
              </div>
            </Col>
          </Row>
        ) : null}
        {inputText("Name", "name", null, true, 100)}
        {inputTextArea("Description", "description", null, true, 500)}
        {inputTextArea("Supported Domains", "supportedDomains", "localhost:61740,localhost:3730", false, 500)}
        {modalData.id ? inputText("Secretkey", "secretkey", null, true, 1000) : ""}
        {inputCheckBox("IsActive", "isActive")}

        <div className="text-right">
          {modalData.id ? (
            <Button onClick={() => onUpdate()}>Update</Button>
          ) : (
            <Button onClick={() => onCreate()}>Create</Button>
          )}
        </div>
      </Container>
    </form>
  )
}

export default FSApplicationForm;