import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/ca-application";
import Wrapper from "./Wrapper";

function CAApplication(props) { 
  
  

  return (
    <React.Fragment>
      <Wrapper {...props} />
    </React.Fragment>
  );
}


const mapStateToProps = state => {
  return {
      applicationsData: state.caApplication.applications,
  };
};

const mapDispatchToProps = dispatch => {
  return {      
      onInitApplications: (params) => dispatch(actions.fetchApplications(params)),
      onUpdateApplication: (params, filterParamsData) => dispatch(actions.fetchUpdateApplication(params, filterParamsData)),
      onDeleteApplication: (id, filterParamsData) => dispatch(actions.fetchDeleteApplication(id, filterParamsData)),
      onCreateApplication: (params, filterParamsData) => dispatch(actions.fetchCreateApplication(params, filterParamsData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CAApplication);
