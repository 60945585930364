import * as appConstant from '../../constants/appConstant';

export const ListTabData = [
    {
        path: appConstant.ROUTE_APPLICATION,
        name: 'NS Application'
    },
    {
        path: appConstant.ROUTE_CAAPPLICATION,
        name: 'CA Application'
    },
    // {
    //     path: appConstant.ROUTE_FSAPPLICATION,
    //     name: 'FS Application'
    // },
];