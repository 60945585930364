import React from 'react';
import { NavLink } from "react-router-dom";

function PageNotFound() {
    return (
        <React.Fragment>
            <div className="page-error">
                <h1 className="page-error__title">404</h1>
                <h4 className="page-error__subtitle">Oops! Page not found</h4>
                <p className="page-error__content">Sorry, but the page you are looking for is not found. Please, make sure you have typed the current URL.</p>
                <div>
                    <NavLink className="btn btn-primary" to="/portal">Back to Dashboard</NavLink>
                </div>
            </div>
        </React.Fragment>
    );
}
export default PageNotFound;