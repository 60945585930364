import http from "../constants/axiosOrders";

const getRolesPaging = (params) => {
	return http.get("/role/search", {
		"params": params
	});
};

const getAllRoles = () => {
	return http.get("/role/getall");
};

const getRoleById = (id) => {
	return http.get(`/role/${id}`);
};

const createRole = (data) => {
	return http.post("/role", data);
}
const updateRole = (data) => {
	return http.put(`/role`, data);
}

const deleteRoleById = (id) => {
	return http.delete(`/role/delete/${id}`);
}

export default {
	getRolesPaging,
	getAllRoles,
	getRoleById,
	createRole,
	updateRole,
	deleteRoleById,
}