import * as actionTypes from "../../constants/actionTypes";
import * as appContants from "../../constants/appConstant";
import services from "../../services/ca-applicationService";
import { updateLoadingStatus } from './app';
import { toast } from "react-toastify";

export const fetchApplicationsSuccess = (applications) => {
  return {
    type: actionTypes.GET_CA_APPLICATIONS_SUCCESS,
    applications: applications,
  };
};

// export const fetchApplicationsFailure = (error) => {
//   return {
//     type: actionTypes.GET_APPLICATIONS_FAILURE,
//     error,
//   };
// };
export const updateApplicationSuccess = (applications) => {
  return {
    type: actionTypes.UPDATE_CA_APPLICATION_SUCCESS,
    applications: applications,
  };
};
export const updateApplicationFailure = (error) => {
  return {
    type: actionTypes.UPDATE_CA_APPLICATION_FAILURE,
    error,
  };
};

// export const deleteApplicationSuccess = (applications) => {
//   return {
//     type: actionTypes.DELETE_APPLICATION_SUCCESS,
//     applications,
//   };
// };
// export const deleteApplicationFailure = (error) => {
//   return {
//     type: actionTypes.DELETE_APPLICATION_FAILURE,
//     error,
//   };
// };

export const createApplicationSuccess = (applications) => {
  return {
    type: actionTypes.CREATE_CA_APPLICATION_SUCCESS,
    applications,
  };
};
export const createApplicationFailure = (error) => {
  return {
    type: actionTypes.CREATE_CA_APPLICATION_FAILURE,
    error,
  };
};

export const fetchApplications = (params) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .getApplicationList(params)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else {
          dispatch(fetchApplicationsSuccess(response.data));
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchUpdateApplication = (params, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .updateApplication(params)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else {
          toast.success(appContants.UPDATE_SUCCESS_MESSAGE, { toastId: "update-success" });
          dispatch(fetchApplications(filterParamsData));
        }
      })
      .catch((error) => {
        // toast.error(error.toString());
        let errorMsg = error.toString();
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchDeleteApplication = (id, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .deleteApplication(id)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else { 
          toast.success(appContants.DELETE_SUCCESS_MESSAGE, { toastId: "delete-success" });
          dispatch(fetchApplications(filterParamsData));
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchCreateApplication = (data, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .createApplication({ ...data })
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else { 
          toast.success(appContants.CREATE_SUCCESS_MESSAGE, { toastId: "create-success" });
          dispatch(fetchApplications(filterParamsData));
        }
      })
      .catch((error) => {
        let errorMsg = error.toString();
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      })
      .finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};


