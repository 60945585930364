import * as actionTypes from "../../constants/actionTypes";

export const initialState = {
	roles: null,
	error: false,
	modalStatus: false,
	loading: false,
	selectDataRoles: [],
}

export const ManageRolesReducer = (state = initialState, action) => {
	switch(action.type){
		case actionTypes.GET_ROLES_SUCCESS:
			return{
				...state,
				roles: action.roles
			};
		case actionTypes.GET_ALL_ROLES_SUCCESS:
			return{
				...state,
				selectDataRoles: action.selectDataRoles
			};
		case actionTypes.SET_EMAIL_TEMPLATE_MODAL:
			return{
				...state,
				modalStatus: action.status
			}
		case actionTypes.LOADING_STATUS:
			return {
				...state,       
				loading: action.loading,   
			}
		default:
			return state;
	}
}

export default ManageRolesReducer;