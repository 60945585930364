import React from "react";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button'

function UserDialog({
    modalStatus,
    onUpdateModalStatus,
    onDeleteData,
    id
  }) {
  return (
    <Modal show={modalStatus} onHide={() => onUpdateModalStatus(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p> Are you sure you want to delete 1 user</p>
            <div>
              <Button className="mr-1" onClick={()=> onUpdateModalStatus(false)} variant="secondary">No</Button>
              <Button onClick={()=> onDeleteData(id) } variant="danger">Yes</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
}

export default UserDialog;
