import React from "react";
import Button from 'react-bootstrap/Button';

function CommonFilter({onFilter, onChangeSearchFilterName, ...props }) {
    return (
        <React.Fragment>
            <div className="form-filter">
                <div className="form-filter__item mr-2">
                    <span className="form-filter__controls">
                        <input className="form-filter__form-input form-control" maxLength="50" onChange={e => onChangeSearchFilterName(e)} placeholder="Filter by name" />
                    </span>
                </div>

                <Button onClick={() => onFilter()} variant="primary">
                    Search
                </Button>
            </div>
        </React.Fragment>
    );
}

export default CommonFilter;