import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./styles.scss";

function UserForm({
  modalData,
  onCreateData,
  onUpdateData,
  onSetEditData,
  ...props
}) {
  const [errors, setErrors] = useState([]);

  let formValid = false;

  const resetErrors = () => {
    while (errors.length > 0) {
      setErrors(errors.pop());
    }
  }

  const checkValid = () => {
    resetErrors();
    let tempErrors = [];
    formValid = true;

    if (!modalData.email || modalData.email == "") {
      tempErrors.push({
        "key": "email",
        "message": "Email is required"
      });
    }

    // if (!modalData.roleId || !modalData.roleId === "") {
    //   tempErrors.push({
    //     "key": "roleId",
    //     "message": "Role is required."
    //   });
    // }

    if (tempErrors.length > 0) formValid = false;
    setErrors(errors.concat(tempErrors));
  }

  const onCreate = () => {
    checkValid();
    if (formValid) onCreateData();
  }

  const onUpdate = () => {
    checkValid();
    if (formValid) onUpdateData();
  }

  const onSetInputValue = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      onSetEditData({
        ...modalData,
        [name]: value
      });
    }
  };

  const onSetCheckbox = (e) => {
    if (e && e.target) {
      const { name, checked } = e.target;
      onSetEditData({
        ...modalData,
        [name]: checked
      });
    }
  };

  const erroMessageRender = (nameField) => {
    return (
      <>
        {(errors.length > 0 && errors.some(item => item.key === nameField)) ? <small className="form-text text-danger">{errors.find(item => item.key === nameField).message}</small> : ""}
      </>
    );
  }

  useEffect(() => {

  }, []);

  //#region Form input
  const inputSelect = (nameDisplay, nameField, dataListSelect = [], isRequired) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <select defaultValue={modalData[nameField]} name={nameField} className="form-filter__form-select form-control" onChange={e => onSetInputValue(e)}>
            {dataListSelect.map((item, index) => {
              var displayNameOption = item.name;
              return <option key={index} value={item.id}>{displayNameOption}</option>;
            })}
          </select>
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }

  const inputText = (nameDisplay, nameField, placeholderValue = null, isRequired, maxLength = null) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <input
            className="form-control"
            type="text"
            placeholder={placeholderValue ? 'Example: ' + placeholderValue : 'Input ' + nameDisplay}
            defaultValue={modalData[nameField]}
            onChange={(e) => onSetInputValue(e)}
            name={nameField}
            maxLength={maxLength ? maxLength : ""}
          />
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }

  const inputCheckBox = (nameDisplay, nameField) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">{nameDisplay}</label>
        </Col>
        <Col xs={3} md={9}>
          <div className="form-label ca-form-label">
            <input
              type="checkbox"
              id={modalData.id}
              defaultChecked={modalData[nameField] ? true : false}
              onChange={(e) => onSetCheckbox(e)}
              name={nameField}
              className="form-label"
            />
          </div>
        </Col>
      </Row>
    );
  }

  //#endregion
  
  return (
    <form>
      <Container className="form-fields applicaion-form">
        {/* {modalData.id ? (
          <Row className="ca-row-form">
            <Col xs={3} md={3} className="text-right">
              <label className="form-label ca-form-label">
                Id
              </label>
            </Col>
            <Col xs={9} md={9}>
              <div className="form-label text-muted ca-form-label">
                {modalData.id}
              </div>
            </Col>
          </Row>
        ) : null} */}
        {inputText("Email", "email", null, true, 255)}
        {inputSelect("Role", "roleId", props.dataListRoles ? props.dataListRoles : [], false)}
        
        {modalData.id ? (
          inputCheckBox("IsActive", "isActive")
        ) : null}
        <div className="text-right">
          {modalData.id ? (
            <Button onClick={() => onUpdate()}>Update</Button>
          ) : (
            <Button onClick={() => onCreate()}>Create</Button>
          )}
        </div>
      </Container>
    </form>
  )
}

export default UserForm;