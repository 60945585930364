import http from "../constants/axiosOrders";


const fetchRequests = (params) => {
	return http.get("/request/lists", {
		"params": params
	});
};

const fetchCARequests = (params) => {
	return http.get("/ClickApprove/EmailRequests", {
		"params": params
	});
};

const fetchCAApplications = (params) => {
	return http.get("/ClickApprove/application/getall", {
		"params": params
	});
};

const fetchRequestById = (params) => {
	return http.get(`/request/${params.id}`, {});
};

const resendEmail = (requestId) => {
	return http.post(`/request/v1/${requestId}/resend`, {});
};


export default {
	fetchRequests,
	resendEmail,
	fetchRequestById,
	fetchCARequests,
	fetchCAApplications
}