import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

function AccessKeyRowCreate({
  onSetLoading,
  applicationId,
  onCloseModal,
  ...props}) {
  const [description, setDescription] = useState("");
  const [errors, setError] = useState("");

  const onTextChange = (e) => {
    setDescription(e.currentTarget.value.trim());
  };  
  const [editMode, setEditMode] = useState(false);
  
  const onCreateRow = async () => {
    isValidForm();
    if (description && description !== "") {
      onSetLoading(true);
      try{
        await props.onCreateAccessKey({id: applicationId , description: description});    
      }
      catch(error) {
        toast.error("Error: function cannot be executed");
      }
      finally{
        onSetLoading(false);     
        setEditMode(false);
        onCloseModal();
      }
    }    
  }

  const isValidForm = () => {
    setError("");
    if (!description || description === "") {
      setError("Description is required")
    }
  }

  const onEdit = () => {
    setError("");
    setEditMode(true)
  }

  return (
    <React.Fragment>
      {
        editMode ? (
          <tr className="form__insert-row">        
            <td colSpan={5}>
              <div className="d-flex">                
                  <div className="form__insert-row__fields">
                    <label>Description</label>
                    <textarea
                      placeholder="Enter your description ..."
                      className="form-control"
                      name="description"
                      onChange={(e) => onTextChange(e)}
                      maxLength="500"
                    ></textarea>
                    {errors !== '' ? (<small className="text-danger">{errors}</small>) : null}
                  </div>
                  <div className="form__insert-row__buttons">
                    <Button className="ml-1" onClick={() => onCreateRow()}>Create</Button>
                    <Button className="ml-1" onClick={() => setEditMode(false)} variant="secondary">Cancel</Button>    
                  </div>                
              </div>
            </td>                    
          </tr>
        ) : (
          <tr>
            <td colSpan={6}>
              <Button onClick={() => onEdit()} className="mr-1">Add</Button>  
            </td>
          </tr>
        )
      }
    </React.Fragment>
  );
}
export default AccessKeyRowCreate;
