/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-16 11:31:43
 * @ Description: Backdrop component
 */

import React from 'react';
import styles from './Backdrop.module.scss';

const backdrop = (props) => {
    return props.showed ? <div 
                            className={styles.BackdropContainer}
                            onClick={props.clicked}></div> : null
}

export default backdrop;