import React, { useState } from "react";
import Button from 'react-bootstrap/Button';

function FSApplicationFilter({ onSetActivePage, setFilterParamsData, filterParamsData, ...props }) {
    const [filterName, setFilterName] = useState();
    const [isActive, setIsActive] = useState();

    const onChangeSearchFilterName = (e) => {
        setFilterName(e.target.value)
    };

    const onFilter = async () => {
        const mockData = {};
        if (filterName) mockData.applicationName = filterName ? filterName.trim() : '';
        mockData.limits = filterParamsData.limits;
        mockData.pageIndex = filterParamsData.pageIndex;
        mockData.isActive = isActive === "All" ? null : isActive;
        await props.onInitApplications(mockData);
        onSetActivePage(1);
        setFilterParamsData(mockData)
    }

    const onSelectedActive = (e) => {
        setIsActive(e.target.value);
    }

    return (
        <React.Fragment>
            <div className="form-filter">
                <div className="form-filter__item mr-2">
                    <select className="form-filter__form-select form-control" onChange={e => onSelectedActive(e)}>
                        <option value={"All"}>All</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                    </select>
                </div>
                <div className="form-filter__item mr-2">
                    <span className="form-filter__controls">
                        <input className="form-filter__form-input form-control" maxLength="50" onChange={e => onChangeSearchFilterName(e)} placeholder="Filter by name" />
                    </span>
                </div>

                <Button onClick={() => onFilter()} variant="primary">
                    Search
                </Button>
            </div>
        </React.Fragment>
    );
}

export default FSApplicationFilter;