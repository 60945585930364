import React, { memo } from "react";
import { Pie } from "react-chartjs-2";
import { Row } from "reactstrap";
import { Col } from "react-bootstrap";
import "./styles.scss";

function Rate(props) {  
  const data = [
    {
      labels: ["Success", "Fail", "Error", "Retried"],
      datasets: [
        {
          label: "Request Rate",
          backgroundColor: ["#00A19C","#D50000", "#FDB924",   "#20419A"],
          data: [
            props.dashboardData && props.dashboardData.requestSummary.requestSuccessAmount > 0
              ? props.dashboardData.requestSummary.requestSuccessAmount
              : 0,
            props.dashboardData && props.dashboardData.requestSummary.requestFailAmount > 0
              ? props.dashboardData.requestSummary.requestFailAmount
              : 0,
            props.dashboardData && props.dashboardData.requestSummary.requestProcessedWithErrorAmount > 0
              ? props.dashboardData.requestSummary.requestProcessedWithErrorAmount
              : 0,
            props.dashboardData && props.dashboardData.requestSummary.requestRetriedAmount > 0
              ? props.dashboardData.requestSummary.requestRetriedAmount
              : 0,
          ],
        },
      ],
    },
    {
      labels: ["Success", "Fail"],
      datasets: [
        {
          label: "Email Rate",
          backgroundColor: ["#00A19C", "#D50000"],
          data: [
            props.dashboardData && props.dashboardData.requestSummary.emailSuccessAmount > 0
              ? props.dashboardData.requestSummary.emailSuccessAmount
              : 0,
            props.dashboardData && props.dashboardData.requestSummary.emailFailAmount > 0
              ? props.dashboardData.requestSummary.emailFailAmount
              : 0,
          ],
        },
      ],
    },
    {
      labels: ["Success", "Fail"],
      datasets: [
        {
          label: "Mobile Push Rate",
          backgroundColor: ["#00A19C", "#D50000"],
          data: [
            props.dashboardData && props.dashboardData.requestSummary.mobilePushSuccessAmount > 0
              ? props.dashboardData.requestSummary.mobilePushSuccessAmount
              : 0,
            props.dashboardData && props.dashboardData.requestSummary.mobilePushFailAmount > 0
              ? props.dashboardData.requestSummary.mobilePushFailAmount
              : 0,
          ],
        },
      ],
    }
  ];
  return (
    <React.Fragment>
      <div className="panel-group no-border">
        <div className="panel-group__header">
          <h3 className="panel-group__title">Rates</h3>
        </div>
        <div className="panel-group__body">
          <Row>
            <Col className="p-5">
              <Pie data={data[0]} />
              <h3 className="pie__legend text-center">Request Rate</h3>
            </Col>
            <Col className="p-5">
              <Pie data={data[1]} />
              <h3 className="pie__legend text-center">Email Rate</h3>
            </Col>
            <Col className="p-5">
              <Pie data={data[2]} />
              <h3 className="pie__legend text-center">Mobile Push Rate</h3>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(Rate);
