import * as actionTypes from "../../constants/actionTypes";

export const initialState = {
	mailConfigs: [],
	error: false,
	modalStatus: false
}

export const mailConfigReducer = (state = initialState, action) => {
	switch(action.type){
		case actionTypes.CREATE_UPDATE_MAILCONFIG_SUCCESS:
			return{
				...state,
				mailConfigs: action.mailConfigs,
				error: false,
				modalStatus: false
			};
		case actionTypes.SET_APPLICATION_MODAL:
			return{
				...state,
				applications: action.applications,
				error: false,
				modalStatus: action.status
			}
		default:
			return state;
	}
}

export default mailConfigReducer;