import axios from "axios";
import {configuration } from './configs';
import jwt_decode from "jwt-decode";


const authHeader = () => {
	//return authorizaiton header with jwt token
	let user = JSON.parse(localStorage.getItem('user'));
	if (user && user.access_token) {
		if(jwt_decode(user.access_token).exp < (Date.now() / 1000)) {
			let text = "Your access token has expired. Please request a new one.";
			window.alert(text);
			localStorage.clear();
			window.location.reload();
		}
		else {
			return { 
				'Authorization': 'Bearer ' + user.access_token, 
				'Access-Control-Allow-Origin': '*', 
				'Access-Control-Allow-Methods':'GET, POST, OPTIONS'
			}
		}
	} else {
		return {
			'Access-Control-Allow-Origin': '*', 
			'Access-Control-Allow-Methods':'GET, POST, OPTIONS'
		};
	}
}


const http = axios.create({
	baseURL: configuration.baseUrlApi
})
http.defaults.headers = authHeader();

// Request interceptor for API calls
http.interceptors.request.use(
	async config => {
	  config.headers = authHeader();
	  return config;
	},
	error => {
	  Promise.reject(error)
});

export default http;