import http from "../constants/fs-axiosOrders";

const getApplicationList = (params) => {
	return http.get("/v3/search/application", {
		"params": params
	});
};

const createApplication = data => {
	return http.post("/v3/create/application", data);
}
const updateApplication = (data) => {
	return http.put(`/v3/update/application`, data);
}

const deleteApplication = id => {
	return http.delete(`/v3/delete/application/${id}`);
}

export default {
	getApplicationList,
	createApplication,
	updateApplication,
	deleteApplication,
}