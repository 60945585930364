/* eslint-disable no-unused-vars */
import React from "react";
import { Modal} from "react-bootstrap";
import "./styles.scss";
import CAApplicationForm from "./CAApplicationForm";

function CAApplicationModal({
  modalShow,
  onCreateData,
  onUpdateData,
  modalData,
  modalMode,
  onShowEditModal,
  onSetEditData,
  allEmailTemplates,
  ...props
}) {

  const modalTitle = {
    "EDIT": "Edit CA Application",
    "ADD": "Add CA Application",
  };
  
  
  return (
    <Modal
      show={modalShow}
      onHide={() => onShowEditModal(false)}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">{`${modalTitle[modalMode]}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalData ? (
          <CAApplicationForm 
            allEmailTemplates={allEmailTemplates}
            modalData={modalData}
            onCreateData={() => onCreateData()}
            onUpdateData={() => onUpdateData()}
            onSetEditData={(data) => onSetEditData(data)}
            {...props}
            />
        ) : null}
      </Modal.Body>
    </Modal>
  );
}
export default CAApplicationModal;
