import React, { memo, useState } from "react";
import CAEmailTemplateList from "../CAEmailTemplateList";
import Loading from '../../../components/Loading';
import CAEmailTemplateModal from "../CAEmailTemplateModal";
import CAEmailTemplateDialog from "../CAEmailTemplateDialog";
import * as appConstant from "../../../constants/appConstant"
import Button from 'react-bootstrap/Button';
import PageHeader from "../../../components/PageHeader";
import CAEmailTemplateFilter from "../CAEmailTemplateFilter";
import Pagination from "react-js-pagination";
import "./styles.scss";
import { toast } from "react-toastify";
import services from "../../../services/ca-email-templateService";

function Wrapper(props) {
  let initMock = {
    "pageIndex": 0,
    "limits": appConstant.GLOBAL_PAGE_SIZE,
    "name": null,
  };

  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalMode, setModalMode] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [filterParamsData, setFilterParamsData] = useState(initMock);
  const [activePage, setActivePage] = useState(1);



  const setFilter = (params) => {
    setFilterParamsData(params);
  }

  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const pageIndex = pageNumber - 1;
    const draft = filterParamsData;
    draft.pageIndex = pageIndex;
    props.onInitApplications(draft)
  }


  const onEditData = (id) => {
    services
      .getEmailTemplateById(id)
      .then((response) => {
        setEditData(response.data);
        setModalMode('EDIT');
        setEditModalStatus(true);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  const onUpdateData = async (data) => {
    await props.onUpdateEmailTemplate(data, filterParamsData);
    setEditModalStatus(false);
  }

  const onOpenCreateModal = async () => {
    setEditData({});
    setModalMode('ADD');
    setEditModalStatus(true);
  }

  const onCreateData = async (data) => {
    await props.onCreateEmailTemplate(data, filterParamsData);
    setEditModalStatus(false);
  }

  const openDeleteModal = (k) => {
    setIdDelete(k);
    setDialogStatus(true);
  }

  const onDeleteData = async (k) => {
    await props.onDeleteEmailTemplate(k, filterParamsData);
    setDialogStatus(false);
  }

  const renderTotalList = () => {
    let dataList = props.emailTemplatesData.emailTemplateDetails ? props.emailTemplatesData.emailTemplateDetails : [];
    return (
      <p style={{ display: 'block', fontSize: 14 }}>Showing: {(activePage - 1) * filterParamsData.limits} to {dataList.length > (activePage * filterParamsData.limits) ? activePage * filterParamsData.limits : (activePage - 1) * filterParamsData.limits + dataList.length} of first {props.emailTemplatesData.totalRequests} records (may have more)</p>
    );
  }

  return (
    <React.Fragment>
      <div className="page">
        <PageHeader pageTitle={"ClickApprove email template"} />
        <div className="page__body">
          <div className="ca-email-template-header">
            <div id="ca-email-template-btn-add">
              <Button variant="primary" className="ml-3 mt-3 mb-0" onClick={() => { onOpenCreateModal() }}>Add New</Button>
            </div>
            <div id="ca-email-template-section-search">
              <CAEmailTemplateFilter
                filterParamsData={filterParamsData} 
                onSetActivePage={(pageId) => setActivePage(pageId)}
                setFilterParamsData={(params) => { setFilter(params) }} {...props}></CAEmailTemplateFilter>
            </div>
          </div>
          <CAEmailTemplateList
            filterParamsData={filterParamsData}
            {...props}
            onDelete={k => openDeleteModal(k)}
            onEdit={k => onEditData(k)}></CAEmailTemplateList>
          {props.emailTemplatesData ? (
            <div className="pagination-container" style={{ flexDirection: 'column' }}>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                itemsCountPerPage={filterParamsData.limits}
                totalItemsCount={props.emailTemplatesData.totalRequests}
                onChange={pageNumber => onPageChange(pageNumber)}
              />
              {renderTotalList()}
            </div>
          ) : null}
          <CAEmailTemplateModal
            {...props}
            modalShow={editModalStatus}
            modalData={editData}
            modalMode={modalMode}
            onUpdateData={(data) => onUpdateData(data)}
            onCreateData={(data) => onCreateData(data)}
            onShowEditModal={(status) => setEditModalStatus(status)}
            onSetEditData={(data) => setEditData(data)}
          />
          <CAEmailTemplateDialog 
            id={idDelete} 
            modalStatus={dialogStatus} 
            onDeleteData={(k) => onDeleteData(k)}  
            onUpdateModalStatus={status => setDialogStatus(status)}
          />
          {props.loading ? (<Loading />) : ("")}
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(Wrapper);
