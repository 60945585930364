import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as appConstants from '../../../../../constants/appConstant';
import Button from "react-bootstrap/Button";
import AccessKeyList from './AccessKeyList';
import * as FaIcon from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ApplicationForm({
  modalData, 
  onCreateData, 
  onUpdateData,
  onSetEditData,
  onCloseModal,
  ...props
}) {
    
  const [errors, setErrors] = useState([]);
  const [checkingApiKey, setCheckingApiKey] = useState(false);
  const [isApiKeyValid, setIsApiKeyValid] = useState(false);
  const [checkApiMessage, setCheckApiMessage] = useState('');

  let formValid = false;

  const onUpdate = () => {
    checkValid();
    if (formValid) onUpdateData();
  }

  const onCreate = () => {
    checkValid();
    if (formValid) onCreateData();
  }

  const onSetInputValue = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      onSetEditData({
        ...modalData,
        [name] : value
      });
    }    
  };

  const onSetCheckbox = (e) => {
    if (e && e.target) {
      const { name, checked } = e.target;
      onSetEditData({
        ...modalData,
        [name] : checked
      });
    }
  };

  const checkValid = () => {
    resetErrors();
    let tempErrors = [];
    formValid = true;
    const emailPattern = appConstants.EMAIL_PATTERN;

    if (!modalData.name || modalData.name.length <= 2) {
      tempErrors.push({
        "key": "name",
        "message": "Name must be greater than 2 characters"
      });  
    }

    if (!modalData.description || modalData.description == "") {
      tempErrors.push({
        "key": "description",
        "message": "Description is required"
      });  
    }
    
    if (!modalData.ownerEmail || modalData.ownerEmail == "") {
      tempErrors.push({
        "key": "ownerEmail",
        "message": "Application Focal is required"
      });  
    } else if(!emailPattern.test(modalData.ownerEmail)) {
      tempErrors.push({
        "key": "ownerEmail",
        "message": "Invalid Format"
      });  
    }

    console.log(tempErrors);
    if (tempErrors.length > 0) formValid = false;
    setErrors(errors.concat(tempErrors));
  }

  const validateApiKey = async () => {
    setCheckingApiKey(true);    
    await props.onCheckApiKey(modalData.serverApiKey); 
    setIsApiKeyValid(props.checkServerApiKeyMessage === 'Valid key');    
    setCheckingApiKey(false);
  }

  const resetErrors = () => {
    while(errors.length > 0) {
      setErrors(errors.pop());
    }   
  }

  useEffect(() => {

  }, []);
    
    return (        
      <form>
        <Container className="form-fields applicaion-form"> 
            {modalData.hashKey ? (
              <Row>
              <Col xs={3} md={3} className="text-right">
                <label className="form-label">
                  Application Id
                </label>
              </Col>
              <Col xs={9} md={9}>
                <div className="form-label text-muted">
                  {modalData.hashKey}
                </div>
              </Col>
            </Row>
            ) : null}
            <Row>
              <Col xs={3} md={3} className="text-right">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
              </Col>
              <Col xs={9} md={9}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  defaultValue={modalData.name}
                  onChange={(e) => onSetInputValue(e)}
                  name="name"
                  maxLength="256"
                />
                {(errors.length > 0 && errors.some(item => item.key === "name")) ? <small className="form-text text-danger">{errors.find(item => item.key === "name").message}</small> : ""}
              </Col>
            </Row>
            <Row>
              <Col xs={3} md={3} className="text-right">
                <label className="form-label">Description<span className="required">*</span></label>
              </Col>
              <Col xs={3} md={9}>
                <textarea
                  className="form-control"                  
                  placeholder="Description"
                  defaultValue={modalData.description}
                  name="description"
                  onChange={(e) => onSetInputValue(e)}
                  maxLength="500"
                />
                {(errors.length > 0 && errors.some(item => item.key === "description")) ? <small className="form-text text-danger">{errors.find(item => item.key === "description").message}</small> : ""}
              </Col>
            </Row> 

            {modalData.hashKey ? (
              <Row>
              <Col xs={3} md={3} className="text-right">
                <label className="form-label">Active</label>
              </Col>
              <Col xs={3} md={9}>
                <div className="form-label">
                  <input
                    type="checkbox"
                    id={modalData.hashKey}
                    defaultChecked={modalData.isActive}
                    onChange={(e) => onSetCheckbox(e)}
                    name="isActive"
                    className="form-label"
                  />
                </div>
              </Col>
            </Row>
            ) : null}

            {modalData.hashKey ? (
              <Row>
              <Col xs={3} md={3} className="text-right">
                <label className="form-label">Using Default Mail Config</label>
              </Col>
              <Col xs={3} md={9}>
                <div className="form-label">
                  <input
                    type="checkbox"
                    id={modalData.hashKey}
                    defaultChecked={modalData.isUsingDefaultMailConfig}
                    onChange={(e) => onSetCheckbox(e)}
                    name="isUsingDefaultMailConfig"
                  />
                </div>
              </Col>
            </Row>
            ) : null}

            {modalData.hashKey ? (
              <Row>
              <Col xs={3} md={3} className="text-right">
                <label className="form-label">Access Key</label>
              </Col>
              <Col xs={3} md={9}>
                <AccessKeyList 
                  onCloseModal={() => onCloseModal()}
                  applicationId={modalData.hashKey} 
                  accessKeysData={modalData.accessKeys}                  
                  {...props}
                  />
              </Col>
            </Row>
            ) : null}

            <Row>
              <Col xs={3} md={3} className="text-right">
                <label className="form-label">
                  Application Focal<span className="required">*</span>
                </label>
              </Col>
              <Col xs={9} md={9}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Application Focal"
                  name="ownerEmail"
                  defaultValue={modalData.ownerEmail}
                  onChange={e => onSetInputValue(e)}
                  maxLength="256"
                />
                {(errors.length > 0 && errors.some(item => item.key === "ownerEmail")) ? <small className="form-text text-danger">{errors.find(item => item.key === "ownerEmail").message}</small> : ""}
              </Col>
            </Row>


            <Row>
              <Col xs={3} md={3} className="text-right">
                <label className="form-label">
                  API Key
                </label>
              </Col>
              <Col xs={9} md={9}>
                <div className={["d-flex", checkingApiKey ? "field-api-checking" : null].join(' ')}>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="API Key"
                    name="serverApiKey"
                    defaultValue={modalData.serverApiKey}
                    onChange={e => onSetInputValue(e)}
                    maxLength="256"
                  />
                  <Button onClick={() => validateApiKey()} variant="warning" className="btn btn-check-valid p-0">
                    Verify Code
                  </Button>
                </div>
                {(props.checkServerApiKeyMessage) ? 
                  <small className={["form-text", props.checkServerApiKeyMessage === 'Valid key' ? "text-success" : "text-danger"].join(' ')}>
                    <FontAwesomeIcon  icon={ props.checkServerApiKeyMessage === 'Valid key' ? FaIcon.faCheck : FaIcon.faExclamationTriangle } /> &nbsp;
                    {props.checkServerApiKeyMessage}
                  </small> 
                  : null}
              </Col>
            </Row>

            <div className="text-right">
              {modalData.hashKey ? (
                <Button onClick={() => onUpdate()}>Update</Button>
              ) : (
                <Button onClick={() => onCreate()}>Create</Button>
              )}
            </div>
          </Container>
          </form>
    )
}

export default ApplicationForm;