import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/ca-email-template";
import Wrapper from "./Wrapper";

function CAEmailTemplate(props) {
    return (
        <React.Fragment>
            <Wrapper {...props} />
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        emailTemplatesData: state.caEmailTemplate.emailTemplates,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitEmailTemplate: (params) => dispatch(actions.fetchEmailTemplates(params)),
        onUpdateEmailTemplate: (params, filterParamsData) => dispatch(actions.fetchUpdateEmailTemplate(params, filterParamsData)),
        onDeleteEmailTemplate: (id, filterParamsData) => dispatch(actions.fetchDeleteEmailTemplate(id, filterParamsData)),
        onCreateEmailTemplate: (params, filterParamsData) => dispatch(actions.fetchCreateApplication(params, filterParamsData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CAEmailTemplate);