import { loginRequest } from "../constants/aadConfig";
import { msalInstance } from "../AppWithAuthAAD";

import jwt_decode from "jwt-decode";
import { configuration } from '../constants/configs';
import axios from "axios";

async function GetRoleByEmail(useremail, accessToken) {
    const authorizaiton = `Bearer ${accessToken}`;
    const http = axios.create({
        baseURL: configuration.baseUrlApi
    })
    http.defaults.headers = { 
        'Authorization': authorizaiton,
        'Access-Control-Allow-Origin': '*', 
		'Access-Control-Allow-Methods':'GET, POST, OPTIONS'
     };
    return http.post("/userrole/getRoleByUserEmail", {
        "email" : useremail
    })
        .then((res) => {
            localStorage.setItem('roles', JSON.stringify(res.data));
        })
        .catch((err) => {
            console.log(err);
        });
}

export async function getInfroFromTokenAAD(accessToken) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }
    let user = {
        access_token: accessToken
    };
    localStorage.clear();
    localStorage.setItem('user', JSON.stringify(user));
    let userInfo = jwt_decode(accessToken);
    await GetRoleByEmail(userInfo.unique_name, accessToken);
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('clientTimeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
    return userInfo;
}

