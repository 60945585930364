import React from "react";

function PageHeader(props) {
  return (
    <React.Fragment>
      <div className="page__header">
        <h2 className="page__title">{props.pageTitle}</h2>
        {props.externalHeaderActions ? props.externalHeaderActions : ''}
      </div>
    </React.Fragment>
  );
}

export default PageHeader;
