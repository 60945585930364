import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../constants/aadConfig";
import './styles.scss';

function LoginAAD(props) {
  const { instance } = useMsal();

  const handleLogin = () => {
    localStorage.clear();
    instance.loginRedirect(loginRequest)
      .catch(e => {
        console.log(e);
      });
  }

  handleLogin();
  return (
    // <div className="container-LoginAAD">
    //     <Button id="btn-login-aad" onClick={() => handleLogin()} className="btn btn-secondary btn-secondary-2">
    //       <FontAwesomeIcon id="icon-LoginAAD" icon={FaIcon.faHospitalUser } />
    //       Login with Azure AD
    //     </Button>
    // </div>
    <></>
  );
}

export default LoginAAD;