import * as actionTypes from "../../constants/actionTypes";
import * as appContants from "../../constants/appConstant";
import services from "../../services/manageUserService";
import { updateLoadingStatus } from './app';
import { toast } from "react-toastify";

export const fetchUsersSuccess = (users) => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    users: users,
  };
};

export const fetchUsers = (params) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .getUsersPaging(params)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else {
          dispatch(fetchUsersSuccess(response.data));
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchUpdateUser = (params, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .updateUser(params)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else {
          toast.success(appContants.UPDATE_SUCCESS_MESSAGE, { toastId: "update-success" });
          dispatch(fetchUsers(filterParamsData));
        }
      })
      .catch((error) => {
        // toast.error(error.toString());
        let errorMsg = error.toString();
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchDeleteUser = (id, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .deleteUserById(id)
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else { 
          toast.success(appContants.DELETE_SUCCESS_MESSAGE, { toastId: "delete-success" });
          dispatch(fetchUsers(filterParamsData));
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchCreateUser = (data, filterParamsData) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .createUser({ ...data })
      .then((response) => {
        if(response.data === actionTypes.RESPONESE_ENDPOINT_NOT_EXIST) {
          toast.error(actionTypes.MS_ERRO_ENDPOINT_NOT_EXIST);
          dispatch(updateLoadingStatus(false));
        } else { 
          toast.success(appContants.CREATE_SUCCESS_MESSAGE, { toastId: "create-success" });
          dispatch(fetchUsers(filterParamsData));
        }
      })
      .catch((error) => {
        let errorMsg = error.toString();
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      })
      .finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};