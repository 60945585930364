import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import './assets/fonts/pet-icons/styles.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import jwt_decode from "jwt-decode";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import applicationReducer from "./store/reducers/application"
import dashboardReducer from "./store/reducers/dashboard"
import mailConfigReducer from "./store/reducers/mailConfig"
import requestsReducer from "./store/reducers/requests"
import caApplicationReducer from './store/reducers/ca-application';
import fsApplicationReducer from './store/reducers/fs-application';
import CAEmailTemplateReducer from './store/reducers/ca-email-template';
import AppWithAuthAAD from  './AppWithAuthAAD';
import ManageRolesReducer from './store/reducers/manage-roles-reducer';
import ManageUsersReducer from './store/reducers/manage-users-reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    application: applicationReducer,
    mailConfig: mailConfigReducer,
    dashboard: dashboardReducer,
    requests: requestsReducer,
    caApplication: caApplicationReducer,
    fsApplication: fsApplicationReducer,
    caEmailTemplate: CAEmailTemplateReducer,
    manageUsers: ManageUsersReducer,
    manageRoles: ManageRolesReducer,
})
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
if (localStorage.getItem('user')) {
    const token = JSON.parse(localStorage.getItem('user')).access_token;
    const userInfo = jwt_decode(token);
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
}

const app = (
    <Provider store={store}>
        <BrowserRouter basename="/">
            <AppWithAuthAAD />
        </BrowserRouter>
    </Provider>

);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
