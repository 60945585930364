/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Your name
 * @ Modify Time: 2019-11-16 11:30:51
 * @ Description: Use Aux with class name.
 */

import React from 'react';

const AuxClass = (props) => (
    <div className={props.classes}>{props.children}</div>
);

export default AuxClass;