import React, { useEffect, useState } from "react";
import * as appConstant from '../../../../constants/appConstant';
import moment from "moment";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import * as FaIcon from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RequestsList({
  onConfirm,
  filterParamsData, 
  setFilterParamsData,
  ...props}) {

  const [isModalShow, setModal] = useState();
  const [isShowRequestDetail, setRequestModal] = useState();
  const [pendingSelectingId, setPendingSelectingId] = useState();
  const [sendRequestId, setSendRequestId] = useState();
  

  const initMock = {
    "pageIndex": 0,
    "limits": appConstant.GLOBAL_PAGE_SIZE
  }
  
  
  const getStatusNameFromId = (statusId) => {
    const draft = appConstant.STATUS_LIST.find(item => item.value === statusId);
    return draft.name;
  }
  
  const getCssClassNameFromId = (statusId) => {
    const draft = appConstant.STATUS_LIST.find(item => item.value === statusId);
    return draft.cssClassName;
  }

  const refreshList = async () => {    
    await props.onInitRequests(filterParamsData);
  }

  // const onGetRequestDetail = async (id) => {
  //   setRequestModal(true);
  //   await props.onGetRequestDetail({id: id});
  //   document.getElementById("json").textContent = JSON.stringify(props, undefined, 2);
  // }

  const onConfirmPopup = (id) => {
    setModal(true);
    setPendingSelectingId(id);
  }

  const onResendEmail = async (e) => {
    setSendRequestId(pendingSelectingId);    
    await props.onResendEmail({requestId: pendingSelectingId, params: filterParamsData});    
    setModal(false); 
  }
  
  useEffect(() => {
    if (Object.keys(filterParamsData).length === 0) {
      props.onInitRequests(initMock);
      setFilterParamsData(initMock);
    } else {
      refreshList();
    }
  }, [sendRequestId]);

  return (
    <React.Fragment>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-left">Application Name</th>
            <th className="text-left" width="40%">Request Title</th>
            <th className="text-left">Reference Id</th>
            <th className="text-center">Type</th>
            <th className="text-center">Date</th>
            <th className="text-center">Status</th>
            <th className="text-right"></th>
          </tr>
        </thead>
        <tbody>
          {props.requestsData && props.requestsData.notificationRequestsDetails ? (            
            props.requestsData.notificationRequestsDetails.map((data) => (
              <tr key={data.id}>
                <td className="text-left">{data.applicationName}</td>
                <td className="text-left" title={data.requestTitle}>{data.requestTitle}
                {(() => {
                  if(data.result !== null) {
                    return <span className="error-result">Msg: {data.result}</span>
                  }
                })()}
                
                </td>
                <td className="text-left" title={data.referenceId}>{data.referenceId}</td>
                <td className="text-center">
                {(() => {
                  if(data.notificationType == 0) {
                    return <FontAwesomeIcon icon={FaIcon.faEnvelope } />
                  }
                  else if(data.notificationType == 1) {
                    return <FontAwesomeIcon icon={FaIcon.faMobile } />
                  }
                })()}
                </td>
                <td className="text-center">
                  {moment(data.date).format("DD MMM HH:mm:ss")}
                </td>
                <td className="text-center"><span className={getCssClassNameFromId(data.requestStatus)}>{getStatusNameFromId(data.requestStatus)}</span></td>
                <td className="text-center">                  
                {(() => {
                  if(data.isHideContent) {
                    return <Button title='Content is hidden. Please resend from application.'><FontAwesomeIcon icon={FaIcon.faExclamationCircle}/></Button>
                  } else {
                    return <Button  onClick={e => onConfirmPopup(data.id)}>Resend</Button>
                  }
                })()}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan="6">
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal show={isModalShow} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Resend Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Are you sure you want to resend this request?</p>
          <div className="text-center">
            <Button className="mr-1" onClick={() => setModal(false)} variant="secondary">No</Button>
            <Button data-id={pendingSelectingId} onClick={e => onResendEmail()} variant="danger">Yes</Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={isShowRequestDetail} onHide={() => setRequestModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Resend Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre id="json"></pre>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default RequestsList;
