import cssVars from 'css-vars-ponyfill';
import * as AppConstant from '../../constants/appConstant';
import * as LocalStorage from '../LocalStorage/localstorage';


export const colorTransform = ($hexColor) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    $hexColor = $hexColor.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec($hexColor);

    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        1] : null;
};

export const appliedTheme = () => {
    return LocalStorage.getData('theme') || AppConstant.DEFAULT_THEME || 'purple';
}

export const setTheme = (theme) => {
    const themeArr = Object.keys(AppConstant.THEMES).map(themeKey => {
        return themeKey;
    });
    if (!theme || themeArr.indexOf(theme) === -1) {
        applyTheme(AppConstant.DEFAULT_THEME);
    } else {
        LocalStorage.setData('theme', theme);
        applyTheme(theme);
    }
}

const applyTheme = (theme) => {
    let cssPonyfill = {};

    const willApplyTheme = AppConstant.THEMES[theme];
    const appliedColor = {
        ...willApplyTheme,
        ...AppConstant.THEME_UTILITIES
    }

    Object.keys(appliedColor).forEach(property => {
        const rgbColor = colorTransform(appliedColor[property]).slice(0, 3).toString();
        document.documentElement.style.setProperty(
            '--' + property,
          appliedColor[property]
        );
        document.documentElement.style.setProperty(
            '--' + property + '-rgb',
            rgbColor
        );
        cssPonyfill['--' + property] = appliedColor[property];
        cssPonyfill['--' + property + '-rgb'] = rgbColor;
    });

    cssVars({
        variables: cssPonyfill
    });
}

const theme = (props) => {

    if (!props.theme) {
        setTheme(AppConstant.DEFAULT_THEME);
    } else {
        setTheme(props.theme);
    }
    return null;
    
}

export default theme;
