export const getData = (key) => {
    const res = localStorage.getItem(key);
    let actualRes;
    try {
        actualRes = JSON.parse(res);
    } catch (error) {
        actualRes = res;
    }
    return actualRes;
}

export const setData = (key, value) => {
    let savedVal;
    if (typeof value === 'object') {
        savedVal = JSON.stringify(value);
    } else {
        savedVal = value;
    }
    localStorage.setItem(key, savedVal);
}

export const removeKey = (key) => {
    localStorage.removeItem(key);
}

export const clear = () => {
    localStorage.clear();
}