import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import CommonFilter from '../../Common';

function CAEmailTemplateFilter({onSetActivePage, setFilterParamsData, filterParamsData,...props }) {
    const [filterName, setFilterName] = useState();

    const onChangeSearchFilterName = (e) =>{
        setFilterName(e.target.value)
    };

    const onFilter = async () => {
        const mockData = {};
        if(filterName) mockData.name = filterName ? filterName.trim() : '';
        mockData.limits = filterParamsData.limits;
        mockData.pageIndex = filterParamsData.pageIndex;
        await props.onInitEmailTemplate(mockData);
        onSetActivePage(1);
        setFilterParamsData(mockData)
      }

    return (
        <CommonFilter onFilter = {() => onFilter()} onChangeSearchFilterName = {e => onChangeSearchFilterName(e)} {...props}></CommonFilter>
    );
}

export default CAEmailTemplateFilter;