import React, { memo, useState } from "react";
import FSApplicationList from "../FSApplicationList";
import Loading from '../../../components/Loading';
import FSApplicationModal from "../FSApplicationModal";
import FSApplicationDialog from "../FSApplicationDialog";
import FSApplicationFilter from "../FSApplicationFilter";
import * as appConstant from "../../../constants/appConstant"
import Button from 'react-bootstrap/Button';
import PageHeader from "../../../components/PageHeader";
import Pagination from "react-js-pagination";
import "./styles.scss";

function Wrapper(props) {

  const initState = {
    "id": null,
    "name": null,
    "description": null,
    "isActive": true,
    "supportedDomains": null,
    "secretkey": null,
    "isDeleted": false,
    "createdOn": "",
  }

  let initMock = {
    "pageIndex": 0,
    "limits": appConstant.GLOBAL_PAGE_SIZE,
    "applicationName": null,
    "isActive": null,
  };

  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editData, setEditData] = useState(initState);
  const [modalMode, setModalMode] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [filterParamsData, setFilterParamsData] = useState(initMock);
  const [activePage, setActivePage] = useState(1);

  const setFilter = (params) => {
    setFilterParamsData(params);
  }

  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const pageIndex = pageNumber - 1;
    const draft = filterParamsData;
    draft.pageIndex = pageIndex;
    props.onInitApplications(draft)
  }

  const onEditData = (id) => {
    const item = props.applicationsData.data.find(i => i.id === id);
    setEditData(item);
    setModalMode('EDIT');
    setEditModalStatus(true);
  }

  const onUpdateData = async () => {
    await props.onUpdateApplication(initMock, editData);
    setEditModalStatus(false);
  }

  const onOpenCreateModal = async () => {
    setEditData(initState);
    setModalMode('ADD');
    setEditModalStatus(true);
  }

  const onCreateData = async () => {
    await props.onCreateApplication(initMock, editData);
    setEditModalStatus(false);
  }

  const openDeleteModal = (k) => {
    setIdDelete(k);
    setDialogStatus(true);
  }

  const onDeleteData = async (k) => {
    await props.onDeleteApplication(initMock, k);
    setDialogStatus(false);
  }

  const renderTotalList = () => {
    let applicationDetails = props.applicationsData.data ? props.applicationsData.data : [];
    return (
      <p style={{ display: 'block', fontSize: 14 }}>Showing: {(activePage - 1) * filterParamsData.limits} to {applicationDetails.length > (activePage * filterParamsData.limits) ? activePage * filterParamsData.limits : (activePage - 1) * filterParamsData.limits + applicationDetails.length} of first {props.applicationsData.totalRequests} records (may have more)</p>
    );
  }

  return (
    <React.Fragment>
      <div className="page">
        <PageHeader pageTitle={"FileService Application"} />
        <div className="page__body">
          <div className="ca-application-header">
            <div className="mb-3 ca-application-item-header" id="ca-application-btn-add">
              <Button variant="primary" className="ml-3 mt-3 mb-0" onClick={() => { onOpenCreateModal() }}>Add New</Button>
            </div>
            <div className="mb-9 ca-application-item-header" id="ca-application-section-search">
              <FSApplicationFilter
                filterParamsData={filterParamsData}
                onSetActivePage={(pageId) => setActivePage(pageId)}
                setFilterParamsData={(params) => { setFilter(params) }} {...props}></FSApplicationFilter>
            </div>
          </div>
          <FSApplicationList
            {...props}
            filterParamsData={filterParamsData}
            onDelete={k => openDeleteModal(k)}
            onEdit={k => onEditData(k)}></FSApplicationList>
          {props.applicationsData ? (
            <div className="pagination-container" style={{ flexDirection: 'column' }}>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                itemsCountPerPage={filterParamsData.limits}
                totalItemsCount={props.applicationsData.totalRequests}
                onChange={pageNumber => onPageChange(pageNumber)}
              />
              {renderTotalList()}
            </div>
          ) : null}
          <FSApplicationModal
            {...props}
            modalShow={editModalStatus}
            modalData={editData}
            modalMode={modalMode}
            onUpdateData={() => onUpdateData()}
            onCreateData={() => onCreateData()}
            onShowEditModal={(status) => setEditModalStatus(status)}
            onSetEditData={(data) => setEditData(data)}
          />
          <FSApplicationDialog
            id={idDelete}
            modalStatus={dialogStatus}
            onDeleteData={(k) => onDeleteData(k)}
            onUpdateModalStatus={status => setDialogStatus(status)}
          />
          {props.loading ? (<Loading />) : ("")}
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(Wrapper);
