import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FaIcon from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { toast } from "react-toastify";

function AccessKeysRow({
  payload,
  key,
  applicationId,
  dataRow,
  isLastOne,
  onSetLoading,
  updateRowOnDataList,
  deleteRowOnDataList,
  onCloseModal,
  ...props
}) {

  const [editMode , setEditMode] = useState(false);
  const [editData, setEditData] = useState({
    "description": "",
    "key": "",
    "isActive": true
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errors, setErrors] = useState("");
 
  const onCancelRow = () => {
    setEditMode(false);
    setErrors("");
  }

  const onEditRow = e => {
    const draft = editData;
    draft.description = dataRow.description;
    draft.key = dataRow.key;
    draft.isActive = dataRow.isActive;
    setEditData(draft);
    setEditMode(true)
  }

  const onUpdateRow = async e => {    
    isValidForm();
    if (editData.description && editData.description !== "") {      
      onSetLoading(true);
      try {
        const draft = editData;
        draft.key = e.currentTarget.dataset.key;
        setEditData(draft);
        await props.onUpdateAccessKey({id: applicationId, data: editData});   
        updateRowOnDataList(editData)
      }
      catch (error) {
        toast.error("Error: function cannot be executed");
      }
      finally{
        setEditMode(false);
        onSetLoading(false);
      }
    }
  }  

  const onDeleteRow = async (e) => {
    const currentKey = e.currentTarget.dataset.key;
    onSetLoading(true);
    try {
      const cloneKey = encodeURIComponent(currentKey);
      await props.onDeleteAccessKey({id: applicationId ,keyId: cloneKey});         
    }
    catch (error) {
      toast.error("Error: function cannot be executed");
    }
    finally{
      deleteRowOnDataList(currentKey)
      setConfirmDelete(false);
      onSetLoading(false);
    }
  }

  const onTextChange = e => {   
    const draft = editData;
    draft.description = e.currentTarget.value.trim();
    setEditData(draft);
  }

  const onCheckboxChange = e => {
    const draft = editData;
    draft.isActive = e.currentTarget.checked
    setEditData(draft);
  }

  const isValidForm = () => {
    setErrors("");
    if (!editData.description || editData.description === "") {
      setErrors("Description is required")
    }
  }

  return (    
    <tr key={dataRow.key}>
      <td className="table__key-id-column">
        <p className="m-0">{dataRow.key}</p>
      </td>
      <td className="table__description-column">
        {editMode ? (
          <div>
            <textarea
              placeholder="Enter your description ..."
              data-key={dataRow.key}
              className="form-control"
              defaultValue={dataRow.description}
              name="description"
              onChange={(e) => onTextChange(e)}
              maxLength="500"
            ></textarea>
            {errors !== "" ? (<small className="text-danger">{errors}</small>) : null}
          </div>
        ) : (
          <p className={editMode ? "d-none" : "m-0"}>{dataRow.description}</p>
        )}
      </td>
      <td className="table__created-on-column">
        <p className="m-0">{moment(dataRow.createdOn).format("MM/DD/YYYY")}</p>
      </td>
      <td className="text-center table__status-column">
        <input
          data-key={dataRow.key}
          disabled={!editMode}
          type="checkbox"
          onChange={(e) => onCheckboxChange(e)}
          defaultChecked={dataRow.isActive}
          className="table__label"
          name="isActive"
        />
      </td>
      <td className="text-right">
        {editMode ? (
          <div className="table__actions">
            <Button data-key={dataRow.key} className={"mr-1"} onClick={e => onUpdateRow(e)}>
              Update
            </Button>
            <Button variant="secondary" onClick={e => onCancelRow()}>
              Cancel
            </Button>
          </div>
        ) : (        
          <div className="table__actions-row">            
            {confirmDelete ? (
              <div className="">
                <div className="text-danger form__comfirm-delete">
                  <small>Are you sure?</small>  
                  <Button data-key={dataRow.key} variant="danger" className="mr-1 ml-2" onClick={e => onDeleteRow(e)}>Yes</Button> 
                  <Button variant="secondary" onClick={() => setConfirmDelete(false)}>No</Button> 
                </div>
              </div>
            ) : (
              <React.Fragment>
                <Button data-key={dataRow.key} variant="link" onClick={() => onEditRow()}>
                  <FontAwesomeIcon icon={FaIcon.faEdit} />
                </Button>           
                {
                  isLastOne ? (
                    null
                  ): (
                    <Button variant="link" text="Delete" onClick={() => setConfirmDelete(true)}>
                      <FontAwesomeIcon className="text-danger" icon={FaIcon.faTrashAlt} />
                    </Button>
                  )
                }
              </React.Fragment>
            )}     
          </div>
        )}
      </td>
    </tr>
  );
}

export default AccessKeysRow;
