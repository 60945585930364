import * as actionTypes from "../../constants/actionTypes";
import services from "../../services/dashboardService";
import { toast } from "react-toastify";
import {updateLoadingStatus} from './app';


export const fetchDashboardDataSuccess = (dashboard) => {
  return {
    type: actionTypes.GET_DASHBOARD_SUCCESS,
    dashboard: dashboard,
  };
};

export const fetchDashboardDataFailure = (error) => {
  return {
    type: actionTypes.GET_DASHBOARD_FAILURE,
    error: error,
  };
};

export const fetchDashboardData = (params) => {
  return (dispatch) => {    
    dispatch(updateLoadingStatus(true));
    services.getDashboardData(params).then((response) => {
      dispatch(fetchDashboardDataSuccess(response.data));
      // toast.success(appContants.FETCH_SUCCESS_MESSAGE);
    }).catch(error => {
      // dispatch(fetchDashboardDataFailure(error))
      toast.error(error.toString());
    }).finally(() => {
      dispatch(updateLoadingStatus(false));
    });
  };
};
