import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as appConstants from '../../../../constants/appConstant';
import Button from "react-bootstrap/Button";
import "./styles.scss";
import * as SelectData from './SelectData';

function CAApplicationForm({
  modalData,
  onCreateData,
  onUpdateData,
  onSetEditData,
  allEmailTemplates,
  ...props
}) {

  const [errors, setErrors] = useState([]);
  let emailTemplateDatas = [
    { id: null, name: 'None'}
  ];

  if (allEmailTemplates && allEmailTemplates.length > 0) {
    emailTemplateDatas = [...emailTemplateDatas.concat(allEmailTemplates)];
  }

  let formValid = false;

  const onUpdate = () => {
    checkValid();
    if (formValid) onUpdateData();
  }

  const onCreate = () => {
    checkValid();
    if (formValid) onCreateData();
  }

  const onSetInputValue = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      onSetEditData({
        ...modalData,
        [name]: value
      });
    }
  };

  const onSetCheckbox = (e) => {
    if (e && e.target) {
      const { name, checked } = e.target;
      onSetEditData({
        ...modalData,
        [name]: checked
      });
    }
  };

  const checkValid = () => {
    resetErrors();
    let tempErrors = [];
    formValid = true;
    const emailPattern = appConstants.EMAIL_PATTERN;

    if (!modalData.name || modalData.name == "") {
      tempErrors.push({
        "key": "name",
        "message": "Name is required"
      });
    }

    if (!modalData.hostUrl || modalData.hostUrl == "") {
      tempErrors.push({
        "key": "hostUrl",
        "message": "Host URL is required"
      });
    }

    if (!modalData.updateRequestStatus || modalData.updateRequestStatus == "") {
      tempErrors.push({
        "key": "updateRequestStatus",
        "message": "Update Request Status is required"
      });
    }

    if (!modalData.isRemarkCompulsary || modalData.isRemarkCompulsary == "") {
      tempErrors.push({
        "key": "isRemarkCompulsary",
        "message": "RemarkCompulsary is required"
      });
    }

    if (!modalData.canApproveRejectOrAmmend || modalData.canApproveRejectOrAmmend == "") {
      tempErrors.push({
        "key": "canApproveRejectOrAmmend",
        "message": "Approve | Reject | Ammend is required"
      });
    }

    if (!modalData.bounceBackIntervalHour || modalData.bounceBackIntervalHour == "") {
      tempErrors.push({
        "key": "bounceBackIntervalHour",
        "message": "Bounce Back Interval Hour is required"
      });
    }

    if (!modalData.bounceBackMaxDay || modalData.bounceBackMaxDay == "") {
      tempErrors.push({
        "key": "bounceBackMaxDay",
        "message": "Bounce Back Max Day is required"
      });
    }

    if (!modalData.applicationUrl || modalData.applicationUrl == "") {
      tempErrors.push({
        "key": "applicationUrl",
        "message": "Application Url is required"
      });
    }

    if (modalData.isApim) {
      if (!modalData.apimUrl || modalData.apimUrl == "") {
        tempErrors.push({
          "key": "apimUrl",
          "message": "Apim Url is required"
        });
      }

      if (!modalData.apimKey || modalData.apimKey == "") {
        tempErrors.push({
          "key": "apimKey",
          "message": "Apim Key is required"
        });
      }
    }


    if (tempErrors.length > 0) formValid = false;
    setErrors(errors.concat(tempErrors));
  }

  const resetErrors = () => {
    while (errors.length > 0) {
      setErrors(errors.pop());
    }
  }

  useEffect(() => {
  }, []);

  const erroMessageRender = (nameField) => {
    return (
      <>
        {(errors.length > 0 && errors.some(item => item.key === nameField)) ? <small className="form-text text-danger">{errors.find(item => item.key === nameField).message}</small> : ""}
      </>
    );
  }

  //#region Form input
  const inputText = (nameDisplay, nameField, placeholderValue = null, isRequired, maxLength = null) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <input
            className="form-control"
            type="text"
            placeholder={placeholderValue ? 'Example: ' + placeholderValue : 'Input ' + nameDisplay}
            defaultValue={modalData[nameField]}
            onChange={(e) => onSetInputValue(e)}
            name={nameField}
            maxLength={maxLength ? maxLength : ""}
          />
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }

  const inputSelect = (nameDisplay, nameField, dataListSelect = [], isRequired) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <select defaultValue={modalData[nameField]} name={nameField} className="form-filter__form-select form-control" onChange={e => onSetInputValue(e)}>
            {dataListSelect.map((item, index) => {
              var displayNameOption = item.value ? `${item.value} -> ${item.name}` : item.name;
              return <option key={index} value={item.value}>{displayNameOption}</option>;
            })}
          </select>
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const inputNumber = (nameDisplay, nameField, placeholderValue = null, isRequired, maxValue = 7) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <input
            onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            type="number"
            min="0"
            max={maxValue}
            className="form-control"
            placeholder={placeholderValue ? 'Example: ' + placeholderValue : 'Input ' + nameDisplay}
            defaultValue={modalData[nameField]}
            onChange={(e) => onSetInputValue(e)}
            name={nameField}
          />
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }

  const inputCheckBox = (nameDisplay, nameField) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">{nameDisplay}</label>
        </Col>
        <Col xs={3} md={9}>
          <div className="form-label ca-form-label">
            <input
              type="checkbox"
              id={modalData.id}
              defaultChecked={modalData[nameField] ? true : false}
              onChange={(e) => onSetCheckbox(e)}
              name={nameField}
              className="form-label"
            />
          </div>
        </Col>
      </Row>
    );
  }


  //#endregion

  return (
    <form>
      <Container className="form-fields applicaion-form">
        {modalData.id ? (
          <Row className="ca-row-form">
            <Col xs={3} md={3} className="text-right">
              <label className="form-label ca-form-label">
                Application Id
              </label>
            </Col>
            <Col xs={9} md={9}>
              <div className="form-label text-muted ca-form-label">
                {modalData.id}
              </div>
            </Col>
          </Row>
        ) : null}
        {inputText("Name", "name", null, true, 100)}
        {inputText("Host URL", "hostUrl", null, true, 150)}
        {inputText("Update Request Status", "updateRequestStatus", "api/ClickApprove/CallBack", true, 150)}
        {inputSelect("Remark Compulsary", "isRemarkCompulsary", SelectData.DATA_IsRemarkCompulsary, false)}
        {inputSelect("Approve | Reject | Ammend", "canApproveRejectOrAmmend", SelectData.DATA_CanApproveRejectOrAmmend, false)}
        {inputNumber("Bounce Back Interval Hour", "bounceBackIntervalHour", null, true, 100)}
        {inputNumber("Bounce Back Max Day", "bounceBackMaxDay", null, true, 100)}
        {inputText("Application Url", "applicationUrl", null, true, 150)}
        {inputText("Error Recipients", "errorRecipients", "test1@petronas.com;test2@petronas.com", false, 500)}
        {inputText("Get RequestStatus", "getRequestStatus", "api/ClickApprove/WeeklyReportCallBack", false, 500)}
        {inputCheckBox("Multiple Callback", "isMultipleCallback")}
        {inputCheckBox("IsApim", "isApim")}
        {inputText("Apim Url", "apimUrl", null, modalData.isApim ? true : false, 100)}
        {inputText("Apim Key", "apimKey", null, modalData.isApim ? true : false, 100)}
        {inputText("Api Key", "apiKey", null, false, 100)}
        {inputText("Header Name", "headerName", null, false, 200)}
        {inputText("Header Value", "headerValue", null, false, 2000)}
        <Row className="ca-row-form">
          <Col xs={3} md={3} className="text-right">
            <label className="form-label ca-form-label">
              Email Template
            </label>
          </Col>
          <Col xs={9} md={9}>
            <select defaultValue={modalData['emailTemplateId']} name='emailTemplateId' className="form-filter__form-select form-control" onChange={e => onSetInputValue(e)}>
              {emailTemplateDatas.map((item, index) => {
                var displayNameOption = item.version ? `${item.name} - (Version ${item.version})` : `${item.name}`;
                return <option key={index} value={item.id}>{displayNameOption}</option>;
              })}
            </select>
            {erroMessageRender('emailTemplateId')}
          </Col>
        </Row>
        <div className="text-right">
          {modalData.id ? (
            <Button onClick={() => onUpdate()}>Update</Button>
          ) : (
            <Button onClick={() => onCreate()}>Create</Button>
          )}
        </div>
      </Container>
    </form>
  )
}

export default CAApplicationForm;