import React, { memo, useEffect, useState } from "react";
import moment from 'moment';
import * as appConstant from '../../../constants/appConstant';
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FaIcon from '@fortawesome/free-solid-svg-icons'

function CAApplicationList({ filterParamsData, onDelete, onEdit, pageIndex, pageLimits, ...props }) {
    let initMock = filterParamsData;
    const refreshList = async () => {
        await props.onInitApplications(filterParamsData);
    }
    
    
    useEffect(() => {
        if (Object.keys(filterParamsData).length === 0) {
            props.onInitApplications(initMock);
        } else {
            refreshList();
        }
    }, []);

    let dataList = [];
    if (props.applicationsData && Array.isArray(props.applicationsData.applicationDetails)) {
        dataList = props.applicationsData.applicationDetails;
    }
    return (
        <React.Fragment>
            <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className="text-center">No.</th>
                            <th className="text-left">Name</th>
                            <th className="text-left">HostUrl</th>
                            <th className="text-left">Error Recipients</th>
                            <th className="text-right"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map((data, index) => {
                            return (
                                <tr key={(pageIndex  * pageLimits ) + index + 1}>
                                    <td className="text-center">{(pageIndex  * pageLimits ) + index + 1}</td>
                                    <td>{data?.name}</td>
                                    <td>{data?.hostUrl}</td>
                                    <td>{data?.errorRecipients}</td>
                                    <td className="text-right table-actions">
                                        <Button title="Edit Application" variant="link" onClick={() => { onEdit(data.id) }}>
                                            <FontAwesomeIcon icon={FaIcon.faEdit} />
                                        </Button>
                                        <Button title="Delele Application" variant="link" onClick={() => { onDelete(data.id) }}>
                                            <FontAwesomeIcon className="text-danger" icon={FaIcon.faTrashAlt} />
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

export default CAApplicationList;