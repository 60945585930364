/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-16 11:29:09
 * @ Description: Navbar - Top bar
 */

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SiteLogo from '../../../assets/images/new-petronas-logo.svg';
import Profile from '../../../components/Profile/Profile';
import * as AppConstant from '../../../constants/appConstant';
import Backdrop from '../../../hoc/Backdrop/Backdrop';
import styles from './Nav.module.scss';
import Sidebar from './Sidebar/Sidebar';


class Nav extends Component {
    state = {
        showSidebar: false
    } 

    onCloseSidebarHandler = () => {
        this.setState({showSidebar: false});
    }

    onOpenSidebarHandler = () => {
        this.setState({showSidebar: true});
    }

    render() {
        return (
            <React.Fragment>
                <nav className={styles.AppNav + " navbar navbar-expand-lg"}>
                    <NavLink className={styles.NavBrand + ' navbar-brand'}
                        to={AppConstant.ROUTE_DASHBOARD}>
                        <div className={styles.LogoContainer}>
                            <img className={styles.LogoImage} alt="Petronas" src={SiteLogo}/>
                        </div>
                    </NavLink>
                    <div className={styles.AppTitle}>
                        <label className={styles.AppTitleLabel + ' mb-0'}>Notification Service Portal</label>
                    </div>
                    <button className={styles.NavbarToggler + ' navbar-toggler'}
                        type="button"
                        data-toggle="collapse"
                        onClick={this.onOpenSidebarHandler}>
                        <div className={styles.NavIcon}></div>
                        <div className={styles.NavIcon}></div>
                        <div className={styles.NavIcon}></div>
                    </button>
                    <div className={styles.MenuItemsContainer + ' collapse navbar-collapse'}>
                        <div className={styles.MenuItems + ' navbar-nav ml-auto'}>
                            {/* <NavLink className={styles.LinkItem + ' nav-item nav-link'}
                                to='/portal/application'>Application</NavLink>
                            <NavLink className={styles.LinkItem + ' nav-item nav-link'}
                                to={AppConstant.ROUTE_ABOUT}>About</NavLink> */}
                            <Profile />
                        </div>
                    </div>
                </nav>
                <Backdrop showed={this.state.showSidebar} clicked={this.onCloseSidebarHandler} />
                <Sidebar showed={this.state.showSidebar} closeme={this.onCloseSidebarHandler}/>
            </React.Fragment>
        )
    }
}

export default Nav;