import React, { memo, useState } from "react";
import ApplicationList from "./ApplicationList";
import Loading from '../../../components/Loading';
import ApplicationModal from "./ApplicationModal";
import ApplicationDialog from "./ApplicationDialog";
import EmailConfigModal from './EmailConfigModal'
import Button from 'react-bootstrap/Button';
import PageHeader from "../../../components/PageHeader"
import http from "../../../constants/axiosOrders"
import { toast } from "react-toastify";
function Wrapper(props) {
  
  const initState = {
    "accessKeys": [],
    "createdOn": "",
    "description": "",
    "hashKey": "",
    "isActive": false,
    "isUsingDefaultMailConfig": false,
    "mailConfigs": [],
    "name": "",
    "ownerEmail": "",
    "rangeKey": "",
    "serverApiKey": ""
  }
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editData, setEditData] = useState(initState);
  const [modalMode, setModalMode] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [emailConfigModalStatus, setEmailConfigModalStatus] = useState(false);
  const [hashKey, setHashKey] = useState();
  const [mailConfigData, setEmailConfigData] = useState([]);
  const [dataApplicationList] = useState();

  const onEditData = (id) => {
    props.onResetServerApiKeyMessage();
    http.get(`/application/${id}`).then((response) => {
      setEditData(response.data);
      setModalMode('EDIT');
      setEditModalStatus(true);
    })
    .catch(error => {
      toast.error(error.toString());
    })
  }

  const onCloseModal = async () => {      
    await props.onInitApplications(); 
    setEditModalStatus(false);
  }

  const onUpdateData = async () => {        
    await props.onUpdateApplication(editData);
    setEditModalStatus(false);
  }

  const onOpenCreateModal = async () => {
    props.onResetServerApiKeyMessage();
    setEditData({});
    setModalMode('ADD');
    setEditModalStatus(true);
  }

  const onCreateData = async () => {
    await props.onCreateApplication(editData);
    setEditModalStatus(false);
  }

  const openDeleteModal = (k) => {
    setHashKey(k);
    setDialogStatus(true);
  }

  const onDeleteData = async (k) => {
    await props.onDeleteApplication(k);
    setDialogStatus(false);
  }
  
  const onEditEmailConfig = (k) => {     
    setHashKey(k);
    setEmailConfigModalStatus(true);          
    const emailConfigs = props.applicationsData.find(item => item.hashKey === k);    
    if(emailConfigs.mailConfigs) {           
      setEmailConfigData(emailConfigs.mailConfigs);
    }    
  }
  const onCreateUpdateMailConfig = async (k, data) => {
    await props.onCreateUpdateMailConfig(k, data);
  }
  
  return (
    <React.Fragment>
      <div className="page">
        <PageHeader pageTitle={"Application"} />
        <div className="page__body">
          <div className="mb-3">            
            <Button variant="primary" className="ml-3 mt-3 mb-0" onClick={() => { onOpenCreateModal() }}>Add New</Button>
          </div>
          <ApplicationList
            {...props}
            dataApplicationList={dataApplicationList}
            onEditEmailConfig={k => onEditEmailConfig(k)}
            onDelete={k => openDeleteModal(k)}
            onEdit={k => onEditData(k)}
          />
          <ApplicationModal
            {...props}
            onCloseModal={() => onCloseModal()}
            modalShow={editModalStatus}
            modalData={editData}
            modalMode={modalMode}
            onUpdateData={() => onUpdateData()}
            onCreateData={() => onCreateData()}
            onShowEditModal={(status) => setEditModalStatus(status)}
            onSetEditData={(data) => setEditData(data)}
          />
          <ApplicationDialog 
            hashKey={hashKey} 
            modalStatus={dialogStatus} 
            onDeleteData={(k) => onDeleteData(k)}  
            onUpdateModalStatus={status => setDialogStatus(status)}
          />
          <EmailConfigModal
            {...props}
            modalShow={emailConfigModalStatus}
            modalData={mailConfigData}
            hashKey={hashKey}
            onShowEditModal={status => setEmailConfigModalStatus(status)}
            onCreateUpdateConfig={(k, data) => onCreateUpdateMailConfig(k, data)}
            onDeleteConfig={(id, param) => props.onDeleteMailConfig(id, param)}
            onInitMailConfig = {(k) => props.onFetchMailConfig(k)}
          />
          {props.loading ? (<Loading />) : ("")}
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(Wrapper);
