export const DATA_CanApproveRejectOrAmmend = [
    { name: 'None', value: "0"},
    { name: 'Approve', value: "1"},
    { name: 'RequiredAmendment', value: "2"},
    { name: 'Approve + RequiredAmendment', value: "3"},
    { name: 'Reject', value: "4"},
    { name: 'Approve + Reject', value: "5"},
    { name: 'RequiredAmendment + Reject', value: "6"},
    { name: 'Approve + RequiredAmendment + Reject', value: "7"},
];

export const DATA_IsRemarkCompulsary = [
    { name: 'None', value: "0"},
    { name: 'Approve', value: "1"},
    { name: 'RequiredAmendment', value: "2"},
    { name: 'Approve + RequiredAmendment', value: "3"},
    { name: 'Reject', value: "4"},
    { name: 'Approve + Reject', value: "5"},
    { name: 'RequiredAmendment + Reject', value: "6"},
    { name: 'Approve + RequiredAmendment + Reject', value: "7"},
];

export const DATA_TemplateEmailVersion = [
    { name: 'Default', value: ""},
    { name: 'Version 2', value: "2"},
];