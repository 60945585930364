/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-16 11:23:57
 * @ Description: Profile component.
 */

import React from "react";
import styles from "./Profile.module.scss";
import {  UserData } from 'pet-oidc-reactjs';
import { useMsal } from "@azure/msal-react";

function Profile() {
  const { instance } = useMsal();
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {
    picture:"",
    name: "",
    title: "",
    division:""
  };
  const LogoutButton = (props) => {
    const onSignOut = () => {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      localStorage.clear();
      props.signOut();
    }
    return (<i onClick={() => {onSignOut()}} className={styles.Icon + ' icon icon-pet-i-logout'}></i>);
  };
  const generateLogoFromName = (name) =>{
    let fullName = '';
    if (name !== '') {
      const pieces = name.split(' ');
      const firstName = pieces[0].charAt(0).toUpperCase();
      const lastName = pieces[pieces.length - 1].charAt(0).toUpperCase();
      fullName = `${firstName}${lastName}`;
    }    
    return fullName
  }
  return (
    <div className={styles.ProfileContainer}>
      {/* <Avatar
        src="https://localost:8888/image.jpg"
        uname=" Full Name"
        characters="2"
      /> */}
      <div className={styles.AvatarArea}>
        {userInfo.picture === "" ? (
          generateLogoFromName(userInfo.name)
        ) : (
          <img alt={userInfo.name} className={styles.AvatarImage} src={userInfo.picture} />
        )}
      </div>
      <section className={styles.UserInfo}>
        <div className={styles.AvatarArea}>
          {userInfo.picture === "" ? (
            generateLogoFromName(userInfo.name)
          ) : (
            <img alt={userInfo.name} className={styles.AvatarImage} src={userInfo.picture} />
          )}
        </div>
        <div className={styles.Information}>
          <h5 className={styles.UserName}>{userInfo.name}</h5>
          <p className={styles.UserJobTitle}>{userInfo.title}</p>
          <p className={styles.UserJobTitle}>{userInfo.division}</p>
        </div>
        <div
          className={
            styles.ActionArea +
            " d-flex justify-content-between align-items-center mt-4"
          }
        >
          <label className="mb-0">Log Out</label>
          <span className={styles.ActionButton}>
            <UserData.Consumer>
              {({signOut}) => <LogoutButton signOut={signOut} />}
            </UserData.Consumer>
          </span>
        </div>
      </section>
    </div>
  );
}

export default Profile;
