import http from "../constants/axiosOrders";

const getApplicationList = (params) => {
	return http.get("/ClickApprove/applications/search", {
		"params": params
	});
};

const getApplicationById = id => {
	return http.get(`/ClickApprove/application/${id}`);
};

const createApplication = data => {
	return http.post("/ClickApprove/application", data);
}
const updateApplication = (data) => {
	return http.put(`/ClickApprove/application`, data);
}

const deleteApplication = id => {
	return http.delete(`/ClickApprove/application/${id}`);
}

export default {
	getApplicationList,
	getApplicationById, 
	createApplication,
	updateApplication,
	deleteApplication,
}