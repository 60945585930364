import React, { useState, useEffect } from "react";
import { NavTab } from "react-router-tabs";
import "./styles.scss";

function TabRoute({ dataTabList, ...props }) {
    const [isHiddenTab, setIsHiddenTab] = useState(false);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        let pathName = window.location.pathname;
        if (dataTabList.filter(x => x.path === pathName).length > 0) {
            setIsHiddenTab(false);
        } else {
            setIsHiddenTab(true);
        }
    }, [window.location.pathname]);

    return (
        <div hidden={isHiddenTab}>
            {dataTabList.map((item, index) => (
                    <NavTab className='tab-application' key={index} to={item.path}>{item.name}</NavTab>
            ))}
        </div>
    );
};

export default TabRoute;