import * as actionTypes from "../../constants/actionTypes";

export const initialState = {
	users: null,
	error: false,
	modalStatus: false,
	loading: false,
}

export const ManageUsersReducer = (state = initialState, action) => {
	switch(action.type){
		case actionTypes.GET_USERS_SUCCESS:
			return{
				...state,
				users: action.users
			};
		case actionTypes.SET_EMAIL_TEMPLATE_MODAL:
			return{
				...state,
				modalStatus: action.status
			}
		case actionTypes.LOADING_STATUS:
			return {
				...state,       
				loading: action.loading,   
			}
		default:
			return state;
	}
}

export default ManageUsersReducer;