import React, { memo, useState } from "react";
import CAApplicationList from "../CAApplicationList";
import Loading from '../../../components/Loading';
import CAApplicationModal from "../CAApplicationModal";
import CAApplicationDialog from "../CAApplicationDialog";
import * as appConstant from "../../../constants/appConstant"
import Button from 'react-bootstrap/Button';
import PageHeader from "../../../components/PageHeader";
import CAApplicationFilter from "../CAApplicationFilter";
import Pagination from "react-js-pagination";
import "./styles.scss";
import { toast } from "react-toastify";
import services from "../../../services/ca-applicationService";
import servicesEmailTemplates from "../../../services/ca-email-templateService";


function Wrapper(props) {

  // const initState = {
  //   "accessKeys": [],
  //   "createdOn": "",
  //   "description": "",
  //   "hashKey": "",
  //   "isActive": false,
  //   "isUsingDefaultMailConfig": false,
  //   "mailConfigs": [],
  //   "name": "",
  //   "ownerEmail": "",
  //   "rangeKey": "",
  //   "serverApiKey": ""
  // }

  let initMock = {
    "pageIndex": 0,
    "limits": appConstant.GLOBAL_PAGE_SIZE,
    "name": null,
  };

  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editData, setEditData] = useState({});
  const [modalMode, setModalMode] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [filterParamsData, setFilterParamsData] = useState(initMock);
  const [activePage, setActivePage] = useState(1);
  const [allEmailTemplates, setAllEmailTemplates] = useState([]);
  const [pageIndex, setPageIndex] = useState(initMock.pageIndex);



  const setFilter = (params) => {
    setFilterParamsData(params);
    setActivePage(params.pageIndex);
    const pageIndex = (params.pageIndex - 1) < 0 ? 0 : (params.pageIndex - 1);
    setPageIndex(pageIndex);
  }

  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const pageIndex = pageNumber - 1;
    setPageIndex(pageIndex);
    const draft = filterParamsData;
    draft.pageIndex = pageIndex;
    props.onInitApplications(draft)
  }


  const onEditData = async (id) => {
    servicesEmailTemplates.getAllEmailTemplate().then((responseData) => {
      setAllEmailTemplates(responseData.data);
      services
        .getApplicationById(id)
        .then((response) => {
          let item = response.data;
          if (item && item.canApproveRejectOrAmmend === 0) {
            item.canApproveRejectOrAmmend = "0";
          }
          if (item && item.isRemarkCompulsary === 0) {
            item.isRemarkCompulsary = "0";
          }
          setEditData(item);
          setModalMode('EDIT');
          setEditModalStatus(true);
        })
        .catch((error) => {
          toast.error(error.toString());
        });
    })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  const onUpdateData = async () => {
    if (editData && editData.emailTemplateId === 'None') {
      editData.emailTemplateId = null;
    }
    await props.onUpdateApplication(editData, filterParamsData);
    setEditModalStatus(false);
  }

  const onOpenCreateModal = async () => {
    let initDataModal = {
      "isRemarkCompulsary": "0",
      "canApproveRejectOrAmmend": "0",
      "isApim": false,
      "isMultipleCallback": false,
      "emailTemplateId": null
    }
    servicesEmailTemplates.getAllEmailTemplate().then((responseData) => {
      setAllEmailTemplates(responseData.data);
      setEditData(initDataModal);
      setModalMode('ADD');
      setEditModalStatus(true);
    })
      .catch((error) => {
        toast.error(error.toString());
      });
  }

  const onCreateData = async () => {
    if (editData && editData.emailTemplateId === 'None') {
      editData.emailTemplateId = null;
    }
    await props.onCreateApplication(editData, filterParamsData);
    setEditModalStatus(false);
  }

  const openDeleteModal = (k) => {
    setIdDelete(k);
    setDialogStatus(true);
  }

  const onDeleteData = async (k) => {
    await props.onDeleteApplication(k, filterParamsData);
    setDialogStatus(false);
  }

  const renderTotalList = () => {
    let applicationDetails = props.applicationsData.applicationDetails ? props.applicationsData.applicationDetails : [];
    return (
      <p style={{ display: 'block', fontSize: 14 }}>Showing: {((activePage - 1) * filterParamsData.limits) >= 0 ? ((activePage - 1) * filterParamsData.limits) : 0} to {applicationDetails.length > (activePage * filterParamsData.limits) ? activePage * filterParamsData.limits : (activePage - 1) * filterParamsData.limits + applicationDetails.length} of first {props.applicationsData.totalRequests} records (may have more)</p>
    );
  }

  return (
    <React.Fragment>
      <div className="page">
        <PageHeader pageTitle={"ClickApprove Application"} />
        <div className="page__body">
          <div className="ca-application-header">
            <div className="mb-3 ca-application-item-header" id="ca-application-btn-add">
              <Button variant="primary" className="ml-3 mt-3 mb-0" onClick={() => { onOpenCreateModal() }}>Add New</Button>
            </div>
            <div className="mb-9 ca-application-item-header" id="ca-application-section-search">
              <CAApplicationFilter
                filterParamsData={filterParamsData}
                onSetActivePage={(pageId) => setActivePage(pageId)}
                setFilterParamsData={(params) => { setFilter(params) }} {...props}></CAApplicationFilter>
            </div>
          </div>
          <CAApplicationList
            filterParamsData={filterParamsData}
            pageIndex={pageIndex}
            pageLimits={initMock.limits}
            {...props}
            onDelete={k => openDeleteModal(k)}
            onEdit={k => onEditData(k)}></CAApplicationList>
          {props.applicationsData ? (
            <div className="pagination-container" style={{ flexDirection: 'column' }}>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                itemsCountPerPage={filterParamsData.limits}
                totalItemsCount={props.applicationsData?.totalRequests}
                onChange={pageNumber => onPageChange(pageNumber)}
              />
              {renderTotalList()}
            </div>
          ) : null}
          <CAApplicationModal
            {...props}
            allEmailTemplates={allEmailTemplates}
            modalShow={editModalStatus}
            modalData={editData}
            modalMode={modalMode}
            onUpdateData={() => onUpdateData()}
            onCreateData={() => onCreateData()}
            onShowEditModal={(status) => setEditModalStatus(status)}
            onSetEditData={(data) => setEditData(data)}
          />
          <CAApplicationDialog
            id={idDelete}
            modalStatus={dialogStatus}
            onDeleteData={(k) => onDeleteData(k)}
            onUpdateModalStatus={status => setDialogStatus(status)}
          />
          {props.loading ? (<Loading />) : ("")}
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(Wrapper);
