import React from "react";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button'

function ApplicationDialog({
    modalStatus,
    onUpdateModalStatus,
    onDeleteData,
    hashKey
  }) {
  return (
    <Modal show={modalStatus} onHide={() => onUpdateModalStatus(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p> Are you sure you want to delete 1 application</p>
            <div>
              <Button className="mr-1" onClick={()=> onUpdateModalStatus(false)} variant="secondary">No</Button>
              <Button onClick={()=> onDeleteData(hashKey) } variant="danger">Yes</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
}

export default ApplicationDialog;
