import * as actionTypes from "../../constants/actionTypes";
import * as appContants from "../../constants/appConstant";
import services from "../../services/fs-applicationService";
import { updateLoadingStatus } from './app';
import { toast } from "react-toastify";

export const fetchApplicationsSuccess = (applications) => {
  return {
    type: actionTypes.GET_FS_APPLICATIONS_SUCCESS,
    applications: applications,
  };
};

export const fetchApplications = (params) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .getApplicationList(params)
      .then((response) => {
        dispatch(fetchApplicationsSuccess(response.data));
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchUpdateApplication = (filterparams, params) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .updateApplication(params)
      .then((response) => {
        toast.success(appContants.UPDATE_SUCCESS_MESSAGE, { toastId: "update-success" });
        dispatch(fetchApplications(filterparams));
      })
      .catch((error) => {
        // toast.error(error.toString());
        let errorMsg = error.toString();
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchDeleteApplication = (filterparams, id) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .deleteApplication(id)
      .then((response) => {
        toast.success(appContants.DELETE_SUCCESS_MESSAGE, { toastId: "delete-success" });
        dispatch(fetchApplications(filterparams));
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fetchCreateApplication = (filterparams, data) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .createApplication({ ...data })
      .then((response) => {
        toast.success(appContants.CREATE_SUCCESS_MESSAGE, { toastId: "create-success" });
        dispatch(fetchApplications(filterparams));
      })
      .catch((error) => {
        let errorMsg = error.toString();
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      })
      .finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};


