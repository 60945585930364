import React, { memo } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";

function RequestByDate(props) {
  const initChartData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };

  const normalizeData = () => {
    if (props.dashboardData && props.dashboardData.requestByDates) {
      props.dashboardData.requestByDates.forEach((item) => {
        initChartData.labels.push(moment(item.requestDate).format("L"));
        initChartData.datasets[0].data.push(item.requestAmount);
        initChartData.datasets[0].backgroundColor.push("#00A19C");
      });
    }
    return initChartData;
  };

  return (
    <React.Fragment>
      <div className="panel-group mb-0">
        <div className="panel-group__header">
          <h3 className="panel-group__title">Request By Date</h3>
        </div>
        <div className="panel-group__body">
          <div className="m-4">
            <Bar          
              data={normalizeData()}
              width={100}
              height={450}
              legend={{display: false}}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(RequestByDate);
