import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/manage-users-action";
import './styles.scss';
import Wrapper from "./Wrapper";

function ManageUsers(props) {
    return (
      <React.Fragment>
        <Wrapper {...props} />
      </React.Fragment>
    );
  }

  const mapStateToProps = (state) => {
    return {
      usersData: state.manageUsers.users,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      onInitUser: (params) => dispatch(actions.fetchUsers(params)),
      onUpdateUser: (params, filterParamsData) => dispatch(actions.fetchUpdateUser(params, filterParamsData)),
      onDeleteUser: (id, filterParamsData) => dispatch(actions.fetchDeleteUser(id, filterParamsData)),
      onCreateUser: (params, filterParamsData) => dispatch(actions.fetchCreateUser(params, filterParamsData)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);