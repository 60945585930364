/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Your name
 * @ Modify Time: 2019-11-16 11:32:10
 * @ Description: Layout component
 */

import React from "react";
import LeftAside from "../../components/widgets/LeftAside/LeftAside";
import Nav from "../../components/widgets/Nav/Nav";
import styles from "./Layout.module.scss";
import AuxClass from "./../Au/AuxClass";
import TabRoute from "../../components/TabRoute";
import { ListTabData } from "./ListTabData";

function Layout(props) {
  return (
    <AuxClass>
      <Nav authenticated={props.authData} />
      <div className={styles.ContentContainer}>
        <section className={styles.LeftAsideModule}>
          <LeftAside />
        </section>
        <section className={styles.RightAsideModule}>
          <section className={styles.Main + " container-fluid"}>
            <TabRoute dataTabList={ListTabData}></TabRoute>
            <div className={styles.LayoutInner}>
              {props.children}
            </div>
          </section>
        </section>
      </div>
    </AuxClass>
  );
}

export default Layout;
