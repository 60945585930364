import React, {memo, useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FaIcon from '@fortawesome/free-solid-svg-icons';
import EmailConfigForm from './EmailConfigForm';
import  "./styles.scss";


function EmailConfigModal({
    modalShow,
    onShowEditModal,
    modalData,
    onCreateUpdateConfig,
    onDeleteConfig,
    hashKey,
    ...props
}) {
    const [emailConfigs, setEmailConfigData] = useState([modalData]);
    const [isEmailConfigFormShow, setIsEmailConfigFormShow] = useState(false);
    const [editEmailData, setEditEmailData] = useState();
    const [deleteDialogShow, setDeleteDialogShow] = useState(false);
    const [email, setEmail] = useState();

    const onDeleteConfirmationShow = (email) => {
      setDeleteDialogShow(true);
      setEmail(email)
    }
    
    const openEmailForm = (email) => {      
      const selectedItem = emailConfigs.find(item => item.email === email);
      setEmail(email)
      setEditEmailData(selectedItem);
      setIsEmailConfigFormShow(true);
    }
    const onOpenCreateEmail = () => {
      setEditEmailData({});
      setIsEmailConfigFormShow(true);
    }
    const cancelEditEmailConfig = () => {
      setEditEmailData({});
      setIsEmailConfigFormShow(false);
    }

    const onDelete = async (email) => {
      await onDeleteConfig(hashKey, email);      
      setDeleteDialogShow(false);
      setEmailConfigData(emailConfigs.filter(item => item.email !== email));
    }

    const cancelEditModal = () => {
      onShowEditModal(false);
      setIsEmailConfigFormShow(false);
      setDeleteDialogShow(false);
    }    

    const onUpdateEmailConfigs = (configData) => {     
      if (emailConfigs.some(item => item.email === configData.email)) {
        let draft = [...emailConfigs];
        draft.forEach(item => {
          if (item.email === configData.email) {
            //item.user = configData.user;
            item.password = configData.password;
            item.email = configData.email;
            item.host = configData.host;
            item.port = configData.port;
            item.ssl = configData.ssl;
            item.useDefaultCredential = configData.useDefaultCredential;
          }
        })
        setEmailConfigData(draft);
      } else {
        setEmailConfigData([...emailConfigs, configData])
      }
      cancelEditModal();
    }

    useEffect(() => {      
      setEmailConfigData(modalData);  
    }, [modalData, props.applicationsData])


    return (
       <Modal show={modalShow} 
              onHide={() => cancelEditModal()}
              size="xl"
        >
        <Modal.Header closeButton>
          <Modal.Title>
            {(!isEmailConfigFormShow && (!editEmailData || Object.keys(editEmailData).length === 0)) ? "Email Config" : ""}
            {(isEmailConfigFormShow && Object.keys(editEmailData).length !== 0) ? `Edit Email Config` : ""}
            {(isEmailConfigFormShow && Object.keys(editEmailData).length === 0) ? `Add Email Config` : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                      
          <div>  
            {
              deleteDialogShow ? (
                <div className="text-center">
                  <p>Are you sure you want to delete this item?</p>
                  <div>
                    <Button onClick={() => {setDeleteDialogShow(false)}} className="mr-1" variant="secondary">No</Button>
                    <Button onClick={() => {onDelete(email)}} variant="danger">Yes</Button>
                  </div>
                </div>
              ) : ""
            }         
            {
              isEmailConfigFormShow ? (
                <EmailConfigForm 
                  onCreateUpdateConfig={onCreateUpdateConfig}                  
                  editData={editEmailData}
                  hashKey={hashKey}
                  onCancel={() => cancelEditEmailConfig()}
                  labelAction={(Object.keys(editEmailData).length !== 0) ? "Update" : "Add"}     
                  onUpdateEmailConfigs={onUpdateEmailConfigs}     
                  emailConfigs={emailConfigs}       
                   />    
              ) : ""
            }           
            {              
              (emailConfigs && !isEmailConfigFormShow && !deleteDialogShow) ? (
                
                <div className="mail-configs">
                    <Button className="ml-0 mr-0 mb-3" onClick={() => { onOpenCreateEmail() }}>Add New</Button>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th className="text-center">Use SSL</th>
                          <th className="text-center">Use Default Credential</th>
                          <th className="table-actions"></th>
                        </tr>
                      </thead>
                      <tbody>                    
                        {                          
                          emailConfigs.map((item, index) => (
                            <tr key={index}>
                              <td className="mail-configs--ellipsis">{item.email}</td>
                              <td  className="text-center">
                                <input
                                  type="checkbox"
                                  disabled
                                  defaultChecked={item.ssl}
                                />                            
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  disabled
                                  defaultChecked={item.useDefaultCredential}
                                />
                              </td>
                              <td className="text-right table-actions">                               
                                <Button title="Edit Email Config" variant="link" onClick={() => { openEmailForm(item.email) }}>
                                  <FontAwesomeIcon icon={FaIcon.faCog } />
                                </Button>
                                <Button title="Delete Email Config" variant="link" onClick={() => { onDeleteConfirmationShow(item.email) }}>
                                  <FontAwesomeIcon className="text-danger" icon={FaIcon.faTimesCircle } />
                                </Button>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
              ) : null
            }
          </div>
          
        </Modal.Body>
      </Modal>
    )
}

export default memo(EmailConfigModal)