import http from "../constants/axiosOrders"

const getApplicationList = () => {
	return http.get("/applications");
};

const getApplication = id => {
	return http.get(`/applications/${id}`);
};

const createApplication = data => {
	return http.post("/application", data);
}
const updateApplication = (id,data) => {
	return http.put(`/application/${id}`, data);
}

const deleteApplication = id => {
	return http.delete(`/application/${id}`);
}

const createUpdateMailConfig = (id, data) => {
	return http.put(`/application/${id}/mail`, data)
}

const deleteMailConfig = (id, email) => {
	return http.delete(`/application/${id}/mail?email=${email}`)
}


const updateAccessKey = ({id, data}) => {
	return http.put(`/application/${id}/key`, data)
}

const deleteAccessKey = ({id, keyId}) => {
	return http.delete(`/application/${id}/key?key=${keyId}`)
}

const createAccessKey = ({id, description}) => {	
	return http.post(`/application/${id}/key`, {"description": description})
}

const checkServerApiKey = (key) => {	
	return http.post(`/application/check-server-api-key?apiServerKey=${key}`)
}

export default {
	getApplicationList,
	getApplication, 
	createApplication,
	updateApplication,
	deleteApplication,
	createUpdateMailConfig,
	deleteMailConfig,
	updateAccessKey,
	deleteAccessKey,
	createAccessKey,
	checkServerApiKey
}