import React, { memo, useEffect, useState } from "react";
import moment from 'moment';
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FaIcon from '@fortawesome/free-solid-svg-icons'

function ApplicationList({onEditEmailConfig, onDelete, onEdit, ...props }) {
  let dataList = [];
  useEffect(() => {
    props.onInitApplications();
  }, []);

  if (Array.isArray(props.applicationsData)) {
    dataList = props.applicationsData;
  }

  return (
    <React.Fragment>
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-center">No.</th>
              <th className="text-left">Name</th>
              <th className="text-left">Description</th>
              <th className="text-left">Created On</th>
              <th className="text-center">Active</th>
              <th className="text-center">Using Default MailConfig</th>
              <th className="text-left">PIC</th>
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((data, index) => {
              return (
                <tr key={index + 1}>
                  <td className="text-center">{index + 1}</td>
                  <td>{data.name}</td>
                  <td>{data.description}</td>
                  <td>{moment(data.createdOn).format('DD-MMM-YYYY HH:mm')}</td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      disabled
                      id="isActive"
                      name="vehicle1"
                      value={data.isActive}
                      checked={data.isActive}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      disabled
                      id="isUsingDefaultMailConfig"
                      name="vehicle1"
                      value={data.isActive}
                      checked={data.isUsingDefaultMailConfig}
                    />
                  </td>
                  <td>{data.ownerEmail}</td>
                  <td className="text-right table-actions">
                    {data.isUsingDefaultMailConfig ? "" :
                      <Button title="Email Config" variant="link" onClick={() => { onEditEmailConfig(data.hashKey) }}>
                        <FontAwesomeIcon icon={FaIcon.faCog} />
                      </Button>
                    }
                    <Button title="Edit Application" variant="link" onClick={() => { onEdit(data.hashKey) }}>
                      <FontAwesomeIcon icon={FaIcon.faEdit} />
                    </Button>
                    <Button title="Delele Application" variant="link" onClick={() => { onDelete(data.hashKey) }}>
                      <FontAwesomeIcon className="text-danger" icon={FaIcon.faTrashAlt} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default memo(ApplicationList);
