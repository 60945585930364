import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/fs-application";
import Wrapper from "./Wrapper";

function FSApplication(props) { 
  
  

  return (
    <React.Fragment>
      <Wrapper {...props} />
    </React.Fragment>
  );
}


const mapStateToProps = state => {
  return {
      applicationsData: state.fsApplication.applications,
  };
};

const mapDispatchToProps = dispatch => {
  return {      
    onInitApplications: (params) => dispatch(actions.fetchApplications(params)),
    onUpdateApplication: (filterparams, params) => dispatch(actions.fetchUpdateApplication(filterparams, params)),
    onDeleteApplication: (filterparams, id) => dispatch(actions.fetchDeleteApplication(filterparams, id)),
    onCreateApplication: (filterparams, params) => dispatch(actions.fetchCreateApplication(filterparams, params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FSApplication);
