import React from 'react';
import './styles.scss';

function PageUnauthorized() {
    return (
        <React.Fragment>
            <div className="page-error">
                <h1 className="page-error__title">401</h1>
                <h4 className="page-error__subtitle">Unauthorized</h4>
                <p className="page-error__content">Sorry, but you are not authorized for selected section.</p>               
            </div>
        </React.Fragment>
    );
}
export default PageUnauthorized;