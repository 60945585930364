/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-16 11:28:05
 * @ Description: Left aside component. Use for admin, dashboard page.
 */

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import * as FaIcon from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ConstRoute from '../../../constants/appConstant';

import styles from "./LeftAside.module.scss";
import "./styles.scss";

class LeftAside extends Component {
  state = {
    expandedSidebar: false,
    sticky: false,
  };

  toggleLeftAsideMenu = (status) => {
    document.body.style.overflow = "hidden";
    if (this.state.expandedSidebar) document.body.style.overflow = "auto";
    this.setState((prevState, prevProp) => {
      return { expandedSidebar: !prevState.expandedSidebar };
    });
  };

  closeSideBar = () => {
    document.body.style.overflow = "auto";
    this.setState((prevState, prevProp) => {
      return { expandedSidebar: false };
    });
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = (e) => {
    this.setState((prevState, prevProp) => {
      return { sticky: false };
    });
    if (window.scrollY >= 50) {
      this.setState((prevState, prevProp) => {
        return { sticky: true };
      });
    }
  };
  

  render() {
    let actionIcon = (
      <i className={styles.ActionIcon + " icon icon-pet-i-menu-lines"}></i>
    );
    if (this.state.expandedSidebar === true) {
      actionIcon = (
        <i className={styles.ActionIcon + " icon icon-pet-i-close"}></i>
      );
    }

    return (
      <React.Fragment>
        <div
          className={[
            this.state.expandedSidebar ? "left-menu--open" : "left-menu--close",
            this.state.sticky ? "left-menu--sticky" : "",
            "left-menu",
          ].join(" ")}
        >
          <div
            className={[styles.LeftAsideContainer, "left-menu__container"].join(
              " "
            )}
          >
            <div className={styles.ToggleBtnArea}>
              <button className={styles.ToggleButton} type="button" onClick={this.toggleLeftAsideMenu} > {actionIcon} </button>
            </div>
            {/* MENU: LIST of ITEMS*/}
            <ul className={styles.ItemsList + " pl-0"}>
              <li className={styles.Item}>
                <NavLink className={styles.ItemContainer} to="/portal" onClick={this.closeSideBar}>                 
                  <div className="left-menu__wrapper">
                    <span className="left-menu__icon"><FontAwesomeIcon icon={FaIcon.faTachometerAlt } /></span>
                    <div className="left-menu__label">Dashboard</div>
                  </div>
                </NavLink>
              </li>
              <li className={styles.Item}>
                <NavLink className={styles.ItemContainer} to="/portal/applicationspage" onClick={this.closeSideBar}>                 
                  <div className="left-menu__wrapper">
                    <span className="left-menu__icon"><FontAwesomeIcon icon={FaIcon.faCog } /></span>
                    <div className="left-menu__label">Applications</div>
                  </div>
                </NavLink>
              </li>
              <li className={styles.Item}>
                <NavLink className={styles.ItemContainer} to="/portal/requests" onClick={this.closeSideBar}>
                  <div className="left-menu__wrapper">
                    <span className="left-menu__icon"><FontAwesomeIcon icon={FaIcon.faSync } /></span>
                    <div className="left-menu__label">Requests</div>
                  </div>
                </NavLink>
              </li>
              <li className={styles.Item}>
                <NavLink className={styles.ItemContainer} to="/portal/carequests" onClick={this.closeSideBar}>
                  <div className="left-menu__wrapper">
                    <span className="left-menu__icon"><FontAwesomeIcon icon={FaIcon.faRecycle } /></span>
                    <div className="left-menu__label">CA Requests</div>
                  </div>
                </NavLink>
              </li>
              <li className={styles.Item}>
                <NavLink className={styles.ItemContainer} to={ConstRoute.ROUTE_CA_EMAIL_TEMPLATE} onClick={this.closeSideBar}>
                  <div className="left-menu__wrapper">
                    <span className="left-menu__icon"><FontAwesomeIcon icon={FaIcon.faMailBulk } /></span>
                    <div className="left-menu__label">CA Email Template</div>
                  </div>
                </NavLink>
              </li>
              <li className={styles.Item}>
                <NavLink className={styles.ItemContainer} to={ConstRoute.ROUTE_MANAGE_USERS} onClick={this.closeSideBar}>
                  <div className="left-menu__wrapper">
                    <span className="left-menu__icon"><FontAwesomeIcon icon={FaIcon.faUser } /></span>
                    <div className="left-menu__label">Users</div>
                  </div>
                </NavLink>
              </li>
              {/* <li className={styles.Item}>
                <NavLink className={styles.ItemContainer} to={ConstRoute.ROUTE_MANAGE_ROLES} onClick={this.closeSideBar}>
                  <div className="left-menu__wrapper">
                    <span className="left-menu__icon"><FontAwesomeIcon icon={FaIcon.faKey } /></span>
                    <div className="left-menu__label">Roles</div>
                  </div>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
        <div onClick={this.toggleLeftAsideMenu} className="menu--backdrop"></div>
      </React.Fragment>
    );
  }
}

export default LeftAside;
