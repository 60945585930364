/**
 * @ Author: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Create Time: 2019-11-15 22:44:08
 * @ Modified by: Hieu Tran <hieu.tranquoc@petronas.com.my>
 * @ Modify Time: 2019-11-16 11:28:39
 * @ Description: Menubar on mobile.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../../assets/images/logo.png';
import * as AppConstant from '../../../../constants/appConstant';
import { setTheme } from '../../../../context/Theme/theme';
import styles from './Sidebar.module.scss';

const Sidebar = (props) => {

    setTheme(AppConstant.DEFAULT_THEME);

    const [childMenuState, childMenuSetState] = useState(null);

    useEffect(() => {
        if (!props.showed && childMenuState !== null) {
            toggleChildMenu(null, childMenuState);
        }
    }, [props.showed, childMenuState])

    const toggleChildMenu = (event, id) => {
        if (event) {
            event.preventDefault();
        }
        const menuContainer = document.querySelector('#' + id);
        if (!menuContainer) {
            return;
        }
        const classList = menuContainer.classList;
        if (classList.contains(styles.ShowMe)) {
            menuContainer.classList.remove(styles.ShowMe);
            childMenuSetState(null);
        } else {
            menuContainer.classList.add(styles.ShowMe);
            childMenuSetState(id);
        }
    }

    return (
        <div className={styles.SidebarContainer + (props.showed ? ' ' + styles.ShowMe : '')}>
            <ul className={ styles.NavBar + ' navbar-nav'}>
                <li className={styles.NavItem + ' nav-item ' + styles.NavBrand}>
                    <NavLink className={styles.NavbarBrand}
                        to={AppConstant.ROUTE_DASHBOARD} onClick={props.closeme}>
                        <img className={styles.AppLogo} alt='Petronas' src={Logo} />
                    </NavLink>
                    <label className={styles.AppTitle + ' mb-0'}>Notification Service Portal</label>
                </li>
            </ul>
            {/**Menu Item List */}
            <div className={styles.MenuListItemContainer}>
                {/**Menu list */}
                <ul className={ styles.NavBarItems + ' navbar-nav'}>
                    {/** Item list*/}
                    <li className={styles.NavItem} >
                        <a className={styles.NavLink + ' nav-link'}  href='/'>
                            <i className={styles.Icon + ' icon icon-pet-i-user-circle'}></i>
                            <span className="ml-2">Sign In</span>
                        </a>
                    </li>

                    <li className={styles.NavItem}>

                        <a className={styles.NavLink + ' nav-link'}
                            onClick={(event) => toggleChildMenu(event, 'entertainment')}>
                            <i className={styles.Icon + ' icon icon-pet-i-l-ebook'}></i>
                            <span className="ml-2">Entertainment</span>
                        </a>

                        <div className={styles.ChildrenMenu} id="entertainment">
                            <div className={styles.ChildrenHeader}>
                                <h5 className={styles.MenuTitle + ' mb-0'}>
                                    <i className={styles.HeaderIcon + ' icon icon-pet-i-l-ebook'}></i>
                                    <span className={styles.HeaderTitle + ' ml-2'}>Entertainment but today it's so long</span>
                                </h5>
                                <span className={styles.CloseBtn} onClick={(event) => toggleChildMenu(event, 'entertainment')}></span>
                            </div>
                            {/** Children items list */}
                            <ul className={styles.NavBarItems + ' navbar-nav'}>
                                {/** Item list */}
                                <li className={styles.NavItem}>
                                    <a className={styles.NavLink + ' nav-link'} href='/'>
                                        <i className={styles.Icon + ' icon icon-pet-i-link'}></i>
                                        <span className="ml-2">Music</span>
                                    </a>
                                </li>
                                <li className={styles.NavItem}>
                                    <a className={styles.NavLink + ' nav-link'} href='/'>
                                        <i className={styles.Icon + ' icon icon-pet-i-link'}></i>
                                        <span className="ml-2">Video</span>
                                    </a>
                                </li>
                                <li className={styles.NavItem}>
                                    <a className={styles.NavLink + ' nav-link'} href='/'>
                                        <i className={styles.Icon + ' icon icon-pet-i-link'}></i>
                                        <span className="ml-2">Gallery</span>
                                    </a>
                                </li>
                                <li className={styles.NavItem}>
                                    <a className={styles.NavLink + ' nav-link'} href='/'>
                                        <i className={styles.Icon + ' icon icon-pet-i-link'}></i>
                                        <span className="ml-2">vLotte</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className={styles.NavItem}>
                        <NavLink className={styles.NavLink + ' nav-link'}
                            to={AppConstant.ROUTE_ABOUT} onClick={props.closeme}>
                            <i className={styles.Icon + ' icon icon-pet-i-info'}></i>
                            <span className="ml-2">About us</span>
                        </NavLink>
                    </li>
                </ul>
                {/**Site configuration */}
                <ul className={styles.SiteConfig}>
                    <li className="item">
                        <label className="bottom-label">Language:</label>
                    </li>
                    <li className="item">
                        <label className="bottom-label">Theme:</label>
                        {/* <Theme theme={AppConstant.DEFAULT_THEME} /> */}
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;