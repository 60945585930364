import React, { useEffect, useState } from "react";
import * as appConstant from '../../../../constants/appConstant';
import moment from "moment";
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import * as FaIcon from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RequestsList({
  onConfirm,
  filterParamsData,
  setFilterParamsData,
  ...props }) {

  const [isModalShow, setModal] = useState();
  const [isShowRequestDetail, setRequestModal] = useState();
  const [pendingSelectingId, setPendingSelectingId] = useState();
  const [sendRequestId, setSendRequestId] = useState();


  const initMock = {
    "pageIndex": 0,
    "limits": appConstant.GLOBAL_PAGE_SIZE,
    "from": moment().subtract(14, 'days').format('YYYY-MM-DD'),
    "to": moment().format('YYYY-MM-DD')
  }

  const onResendEmail = async (e) => {
    setSendRequestId(pendingSelectingId);
    await props.onResendEmail({ requestId: pendingSelectingId, params: filterParamsData });
    setModal(false);
  }

  const refreshList = async () => {
    await props.onInitRequests(filterParamsData);
  }

  const getCACssClassNameFromId = (statusId) => {
    const draft = appConstant.CA_STATUS_LIST.find(item => item.id === statusId);
    return draft && draft.cssClassName;
  }

  const getCAStatusNameFromId = (statusId) => {
    const draft = appConstant.CA_STATUS_LIST.find(item => item.id === statusId);
    return draft && draft.name;
  }

  // const onConfirmPopup = (id) => {
  //   setModal(true);
  //   setPendingSelectingId(id);
  // }

  // const onGetRequestDetail = async (id) => {
  //   setRequestModal(true);
  //   await props.onGetRequestDetail({ id: id });
  //   document.getElementById("json").textContent = JSON.stringify(props, undefined, 2);
  // }

  useEffect(() => {
    if (Object.keys(filterParamsData).length === 0) {
      props.onInitRequests(initMock);
      setFilterParamsData(initMock);
    } else {
      refreshList();
    }
  }, [sendRequestId]);

  return (
    <React.Fragment>
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-left">Application Name</th>
            <th className="text-left" width="40%">Request Title</th>
            <th className="text-left">Reference Id</th>
            <th className="text-center">Type</th>
            <th className="text-center">Date</th>
            <th className="text-center">Status</th>
            {/* <th className="text-right"></th> */}
          </tr>
        </thead>
        <tbody>
          {props.requestsData && props.requestsData.emailRequestDetails ? (
            props.requestsData.emailRequestDetails.map((data, index) => (
              <tr key={data.id}>
                <td className="text-left">{data.applicationName}</td>
                <td className="text-left" title={data.title}>{data.title}
                  {(() => {
                    if (data.result) {
                      var typeMsg = data.result.includes("Callback Success");
                      if (typeMsg) {
                        return <span key={index} className="success-result">Msg: {data.result}</span>
                      }
                      else {
                        return <span key={index} className="error-result">Msg: {data.result}</span>
                      }
                    }
                  })()}
                </td>
                <td className="text-left" title={data.id}>{data.id}</td>
                <td className="text-center">
                  <FontAwesomeIcon icon={FaIcon.faEnvelope} />
                </td>
                <td className="text-center">
                  {moment(data.sentDateTime).format("DD MMM HH:mm:ss")}
                </td>
                <td className="text-center"><span className={getCACssClassNameFromId(data.status)}>{getCAStatusNameFromId(data.status)}</span></td>
                {/* <td className="text-right">                  
                  <Button  onClick={e => onConfirmPopup(data.id)}>Resend</Button>
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan="6">
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal show={isModalShow} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Resend Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">Are you sure you want to resend this request?</p>
          <div className="text-center">
            <Button className="mr-1" onClick={() => setModal(false)} variant="secondary">No</Button>
            <Button data-id={pendingSelectingId} onClick={e => onResendEmail()} variant="danger">Yes</Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={isShowRequestDetail} onHide={() => setRequestModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Resend Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre id="json"></pre>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default RequestsList;
