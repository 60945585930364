import Logo from '../../assets/images/logo.png';
import React from "react";

function Loading() {
    return (
        <div className="page-loading">
          <div className="page-loading__outer">
            <div className="page-loading__spinner"></div>
            <img className="page-loading__logo" src={Logo} alt="Logo" />
          </div>
        </div>
    )
}

export default Loading;