import React, { memo } from "react";
import { Card, CardBody, CardTitle, Row, CardText, Col } from "reactstrap";
import 'react-count-animation/dist/count.min.css';
import AnimationCount from 'react-count-animation';
import * as FaIcon from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RequestByDate(props) {
  const applicationSetting = {
    start: 0,
    count: props.dashboardData ? props.dashboardData.applicationAmount : 0,
    duration: 1000,
    decimals: 0,
    useGroup: true,
    animation: 'up',
  };

  const requestSetting = {
    start: 0,
    count: props.dashboardData ? props.dashboardData.requestSummary.requestAmount : 0,
    duration: 1000,
    decimals: 0,
    useGroup: true,
    animation: 'up',
  };

  const emailSetting = {
    start: 0,
    count: props.dashboardData ? props.dashboardData.requestSummary.emailAmount : 0,
    duration: 1000,
    decimals: 0,
    useGroup: true,
    animation: 'up',
  };

  const mobilePushSetting = {
    start: 0,
    count: props.dashboardData ? props.dashboardData.requestSummary.mobilePushAmount : 0,
    duration: 1000,
    decimals: 0,
    useGroup: true,
    animation: 'up',
  };

  return (
    <React.Fragment>
      <div className="panel-group no-border">
        <div className="panel-group__header">
          <h3 className="panel-group__title">Summary</h3>
        </div>
        <div className="panel-group__body">
          <Row>
            <Col>
              <Card className="bg-primary counting-card">
                <CardBody className="text-light counting-card__body">
                  <CardTitle className="counting-card__title">
                    Applications
                  </CardTitle>
                  <div className="counting-card__content">
                    <span className="counting-card__counter">
                      {/* {props.dashboardData
                        ? props.dashboardData.applicationAmount
                        : 0} */}
                      <AnimationCount {...applicationSetting}/>
                    </span>
                    <FontAwesomeIcon className="counting-card__icon" icon={FaIcon.faCog } />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="bg-secondary counting-card">
                <CardBody className="text-light counting-card__body">
                  <CardTitle className="counting-card__title">
                    Requests
                  </CardTitle>
                  <div className="counting-card__content">
                    <span className="counting-card__counter">
                      {/* {props.dashboardData
                        ? props.dashboardData.requestAmount
                        : 0} */}
                        <AnimationCount {...requestSetting}/>
                    </span>
                    <FontAwesomeIcon className="counting-card__icon" icon={FaIcon.faSync } />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="bg-warning counting-card">
                <CardBody className="text-light counting-card__body">
                  <CardTitle className="counting-card__title">Emails</CardTitle>
                  <div className="counting-card__content">
                    <span className="counting-card__counter">
                      {/* {props.dashboardData
                        ? props.dashboardData.emailAmount
                        : 0} */}
                        <AnimationCount {...emailSetting}/>
                    </span>
                    <FontAwesomeIcon className="counting-card__icon" icon={FaIcon.faEnvelope } />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="bg-success counting-card">
                <CardBody className="text-light counting-card__body">
                  <CardTitle className="counting-card__title">Mobile Push</CardTitle>
                  <div className="counting-card__content">
                    <span className="counting-card__counter">
                      <AnimationCount {...mobilePushSetting}/>
                    </span>
                    <FontAwesomeIcon className="counting-card__icon" icon={FaIcon.faMobile } />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>        
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(RequestByDate);
