import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./styles.scss";
import CAEmailTemplateTextEditor from "../CAEmailTemplateTextEditor";

function CAEmailTemplateForm({
  modalData,
  onCreateData,
  onUpdateData,
  onSetEditData,
  ...props
}) {
  const [errors, setErrors] = useState([]);
  let formValid = false;
  let modalDataForm = {};
  const onUpdate = () => {
    checkValid(modalDataForm);
    if (formValid) onUpdateData(modalDataForm);
  }

  const onCreate = () => {
    checkValid(modalDataForm);
    if (formValid) onCreateData(modalDataForm);
  }

  const onSetInputValue = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      onSetEditData({
        ...modalData,
        [name]: value
      });
    }
  };

  const onChangeContent = (data) => {
    modalDataForm = data;
  }

  const checkValid = (modalDataForm) => {
    resetErrors();
    let tempErrors = [];
    formValid = true;
    if (!modalDataForm.name || modalDataForm.name == "") {
      tempErrors.push({
        "key": "name",
        "message": "Name is required"
      });
    }

    if (!modalDataForm.content || modalDataForm.content == "" || modalDataForm.content == '<p><br></p>') {
      tempErrors.push({
        "key": "content",
        "message": "Content is required"
      });
    }

    if (!modalDataForm.version || modalDataForm.version == "") {
      tempErrors.push({
        "key": "version",
        "message": "Version is required"
      });
    };

    if (!modalDataForm.amendmentButton || modalDataForm.amendmentButton == "" || modalDataForm.amendmentButton == '<p><br></p>') {
      tempErrors.push({
        "key": "amendmentButton",
        "message": "Amendment Button is required"
      });
    }

    if (!modalDataForm.approveButton || modalDataForm.approveButton == "" || modalDataForm.approveButton == '<p><br></p>') {
      tempErrors.push({
        "key": "approveButton",
        "message": "Approve Button is required"
      });
    }

    if (!modalDataForm.rejectedButton || modalDataForm.rejectedButton == "" || modalDataForm.rejectedButton == '<p><br></p>') {
      tempErrors.push({
        "key": "rejectedButton",
        "message": "Rejected Button is required"
      });
    }

    if (tempErrors.length > 0) formValid = false;
    setErrors(errors.concat(tempErrors));
  }

  const resetErrors = () => {
    while (errors.length > 0) {
      setErrors(errors.pop());
    }
  }
  useEffect(() => {

  }, []);

  const erroMessageRender = (nameField) => {
    return (
      <>
        {(errors.length > 0 && errors.some(item => item.key === nameField)) ? <small className="form-text text-danger">{errors.find(item => item.key === nameField).message}</small> : ""}
      </>
    );
  }

  //#region Form input
  const inputText = (nameDisplay, nameField, placeholderValue = null, isRequired, maxLength = null) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <input
            className="form-control"
            type="text"
            placeholder={placeholderValue ? 'Example: ' + placeholderValue : 'Input ' + nameDisplay}
            defaultValue={modalData[nameField]}
            onChange={(e) => onSetInputValue(e)}
            name={nameField}
            maxLength={maxLength ? maxLength : ""}
          />
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }

  const inputNumber = (nameDisplay, nameField, placeholderValue = null, isRequired) => {
    return (
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <input
            type="number"
            min="0"
            className="form-control"
            placeholder={placeholderValue ? 'Example: ' + placeholderValue : 'Input ' + nameDisplay}
            defaultValue={modalData[nameField]}
            onChange={(e) => onSetInputValue(e)}
            name={nameField}
          />
          {erroMessageRender(nameField)}
        </Col>
      </Row>
    );
  }
  //#endregion

  return (
    <form>
      <Container className="form-fields applicaion-form">
        {modalData.id ? (
          <Row className="ca-row-form">
            <Col xs={3} md={3} className="text-right">
              <label className="form-label ca-form-label">
                Email Template Id
              </label>
            </Col>
            <Col xs={9} md={9}>
              <div className="form-label text-muted ca-form-label">
                {modalData.id}
              </div>
            </Col>
          </Row>
        ) : null}
        {inputText("Name", "name", null, true, null)}
        {inputNumber("Version", "version", null, true)}
        <CAEmailTemplateTextEditor {...props} errors={errors} modalData={modalData} onChangeContent={(e) => onChangeContent(e)}/>
        <div className="text-right">
          {modalData.id ? (
            <Button onClick={() => onUpdate()}>Update</Button>
          ) : (
            <Button onClick={() => onCreate()}>Create</Button>
          )}
        </div>
        <div>
        </div>
      </Container>
    </form>
  )
}

export default CAEmailTemplateForm;