import React from 'react';
import Wrapper from './Wrapper';
import { connect } from "react-redux";
import * as actions from "../../store/actions/dashboard";
import './styles.scss'

function Dashboard (props) {
    return (
       <React.Fragment>
           <Wrapper {...props} />
       </React.Fragment>
    )
}

const mapStateToProps = state => {    
    return {
        dashboardData: state.dashboard.dashboardData,
        loading: state.dashboard.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitDashboardData: (params) => dispatch(actions.fetchDashboardData(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);