export const DEFAULT_THEME = "blue";
export const ROUTE_DASHBOARD = "/portal";
export const ROUTE_ABOUT = "/about";
export const ROUTE_APPLICATION = "/portal/application";
export const ROUTE_CAAPPLICATION = "/portal/caapplication";
export const ROUTE_FSAPPLICATION = "/portal/fsapplication";
export const ROUTE_APPLICATION_PAGE = "/portal/applicationspage";
export const ROUTE_REQUESTS = "/portal/requests";
export const ROUTE_CA_REQUESTS = "/portal/carequests";
export const ROUTE_CA_EMAIL_TEMPLATE = "/portal/caemailtemplate";
export const ROUTE_CREATEAPPLICATION = "/Createapplication";
export const ROUTE_EDITAPPLICATION = "/Editapplication";
export const GLOBAL_PAGE_SIZE = 20;
export const ROUTE_PAGE_NOT_FOUND = "/404";
export const ROUTE_UNAUTHORIZED = "/401";
export const FIREBASE_CLOUD_MESSAGE = 'https://fcm.googleapis.com/fcm/send';
export const ROUTE_MANAGE_ROLES = "/manage/roles";
export const ROUTE_MANAGE_USERS = "/manage/users";

export const THEMES = {
  purple: {
    primary: "#00A19C",
    "primary-50": "#E0F4F3",
    "primary-100": "#B3E3E1",
    "primary-300": "#4DBDBA",
    "primary-700": "#008F8A",
    "primary-900": "#00746E",
    secondary: "#763F98",
    "secondary-50": "#EFE8F3",
    "secondary-100": "#D6C5E0",
    "secondary-300": "#9F79B7",
    "secondary-700": "#633185",
    "secondary-900": "#461B6A",
    "bg-color": "#F2F2F2",
    "text-color": "#212121",
    "text-primary": "#F5F5F5",
    "text-primary-50": "#212121",
    "text-primary-100": "#212121",
    "text-primary-300": "#F5F5F5",
    "text-primary-700": "#F5F5F5",
    "text-primary-900": "#F5F5F5",
    "text-secondary": "#F5F5F5",
    "text-secondary-50": "#212121",
    "text-secondary-100": "#212121",
    "text-secondary-300": "#212121",
    "text-secondary-700": "#F5F5F5",
    "text-secondary-900": "#F5F5F5",
  },
  blue: {
    primary: "#00A19C",
    "primary-50": "#E0F4F3",
    "primary-100": "#B3E3E1",
    "primary-300": "#4DBDBA",
    "primary-700": "#008F8A",
    "primary-900": "#00746E",
    secondary: "#20419A",
    "secondary-50": "#E4E8F3",
    "secondary-100": "#BCC6E1",
    "secondary-300": "#637AB8",
    "secondary-700": "#183288",
    "secondary-900": "#0B1C6C",
    "bg-color": "#F2F2F2",
    "text-color": "#212121",
    "text-primary": "#F5F5F5",
    "text-primary-50": "#212121",
    "text-primary-100": "#212121",
    "text-primary-300": "#F5F5F5",
    "text-primary-700": "#F5F5F5",
    "text-primary-900": "#F5F5F5",
    "text-secondary": "#F5F5F5",
    "text-secondary-50": "#212121",
    "text-secondary-100": "#212121",
    "text-secondary-300": "#212121",
    "text-secondary-700": "#F5F5F5",
    "text-secondary-900": "#F5F5F5",
  },
  yellow: {
    primary: "#00A19C",
    "primary-50": "#E0F4F3",
    "primary-100": "#B3E3E1",
    "primary-300": "#4DBDBA",
    "primary-700": "#008F8A",
    "primary-900": "#00746E",
    secondary: "#FDB924",
    "secondary-50": "#FFF7E5",
    "secondary-100": "#FEEABD",
    "secondary-300": "#FECE66",
    "secondary-700": "#FCAA1B",
    "secondary-900": "#FC930D",
    "bg-color": "#F2F2F2",
    "text-color": "#212121",
    "text-primary": "#F5F5F5",
    "text-primary-50": "#212121",
    "text-primary-100": "#212121",
    "text-primary-300": "#F5F5F5",
    "text-primary-700": "#F5F5F5",
    "text-primary-900": "#F5F5F5",
    "text-secondary": "#F5F5F5",
    "text-secondary-50": "#212121",
    "text-secondary-100": "#212121",
    "text-secondary-300": "#212121",
    "text-secondary-700": "#F5F5F5",
    "text-secondary-900": "#F5F5F5",
  },
  "lime-green": {
    primary: "#00A19C",
    "primary-50": "#E0F4F3",
    "primary-100": "#B3E3E1",
    "primary-300": "#4DBDBA",
    "primary-700": "#008F8A",
    "primary-900": "#00746E",
    secondary: "#BFD730",
    "secondary-50": "#F7FAE6",
    "secondary-100": "#ECF3C1",
    "secondary-300": "#D2E36E",
    "secondary-700": "#B1CD24",
    "secondary-900": "#9BBE13",
    "bg-color": "#F2F2F2",
    "text-color": "#212121",
    "text-primary": "#F5F5F5",
    "text-primary-50": "#212121",
    "text-primary-100": "#212121",
    "text-primary-300": "#F5F5F5",
    "text-primary-700": "#F5F5F5",
    "text-primary-900": "#F5F5F5",
    "text-secondary": "#F5F5F5",
    "text-secondary-50": "#212121",
    "text-secondary-100": "#212121",
    "text-secondary-300": "#212121",
    "text-secondary-700": "#F5F5F5",
    "text-secondary-900": "#F5F5F5",
  },
};

export const THEME_UTILITIES = {
  white: "#FFFFFF",
  "white-smoke": "#F2F2F2",
  "gray-50": "#FAFAFA",
  "gray-100": "#F5F5F5",
  "gray-300": "#E0E0E0",
  gray: "#9E9E9E",
  "gray-700": "#616161",
  "gray-900": "#212121",
  black: "#000000",
  primary: "#00A19C",
  success: "#4CAF50",
  "success-50": "#EAF5EA",
  "success-100": "#C9E7CB",
  "success-300": "#82C785",
  "success-700": "#3C9F40",
  "success-900": "#248627",
  danger: "#D50000",
  "danger-50": "#FAE0E0",
  "danger-100": "#F2B3B3",
  "danger-300": "#E24D4D",
  "danger-700": "#CA0000",
  "danger-900": "#BA0000",
  warning: "#FDB924",
  "warning-50": "#FFF7E5",
  "warning-100": "#FEEABD",
  "warning-300": "#FECE66",
  "warning-700": "#FCAA1B",
  "warning-900": "#FC930D",
  info: "#20419A",
  "info-50": "#E4E8F3",
  "info-100": "#BCC6E1",
  "info-300": "#637AB8",
  "info-700": "#183288",
  "info-900": "#0B1C6C",
};
export const STATUS_LIST = [
  {
    id: "038498f4-5e8b-4147-a96b-f73690b9fa61",
    value: -1,
    name: "All",
    cssClassName: ""
  },
  {
    id: "d94a0eb6-255a-444d-a4b7-e009eb490f3c",
    value: 0,
    name: "Queueing",
    cssClassName: ""
  },
  {
    id: "fd007bf5-f736-4bd6-95cc-d2151d0fb620",
    value: 1,
    name: "Processing",
    cssClassName: ""
  },
  {
    id: "686069fb-3609-4aa1-8cb2-5435eb34066a",
    value: 2,
    name: "Completed",
    cssClassName: ""
  },
  {
    id: "3ef7ab60-cb35-415e-95e1-f0e00efeec2d",
    value: 3,
    name: "Processed With Error",
    cssClassName: ""
  },
  {
    id: "53d7bc62-7523-4d64-b77c-c33d902bbd78",
    value: 4,
    name: "Failed",
    cssClassName: "table__send-failed"
  },
  {
    id: "e44fcf51-19df-4c30-94b6-00da2e1728dd",
    value: 5,
    name: "Retried",
    cssClassName: ""
  },
];
export const CA_STATUS_LIST = [
  {
    id: -1,
    name: "All",
    cssClassName: ""
  },
  {
    id: 0,
    name: "None",
    cssClassName: ""
  },
  {
    id: 1,
    name: "Pending",
    cssClassName: ""
  },
  {
    id: 2,
    name: "Approved",
    cssClassName: ""
  },
  {
    id: 3,
    name: "Rejected",
    cssClassName: ""
  },
  {
    id: 4,
    name: "Require Amendment",
    cssClassName: ""
  },
  {
    id: 5,
    name: "Bounce Back",
    cssClassName: ""
  },
  {
    id: 6,
    name: "Sent Failed",
    cssClassName: ""
  },
  {
    id: 7,
    name: "Sent Error",
    cssClassName: ""
  },
  {
    id: 8,
    name: "Multiple Status",
    cssClassName: ""
  }
]
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NUMBER_PATTERN = /^[0-9]*$/;
export const HOST_NAME_PATTERN = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9/\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9/\-]*[A-Za-z0-9])$/;

export const UPDATE_SUCCESS_MESSAGE = "Update data success!";
export const DELETE_SUCCESS_MESSAGE = "Delete data success!";
export const FETCH_SUCCESS_MESSAGE = "Fetch data success!";
export const CREATE_SUCCESS_MESSAGE = "Create data success!";

export const UPDATE_FAIL_MESSAGE = "Update data failed!";
export const DELETE_FAIL_MESSAGE = "Delete data failed!";
export const FETCH_FAIL_MESSAGE = "Fetch data failed!";
export const CREATE_FAIL_MESSAGE = "Create failed!";


export const FETCH_APPLICATION_SUCCESS_MESSAGE = "Fetch application data successfull!";
export const DELETE_APPLICATION_SUCCESS_MESSAGE = "Delete application data successfull!";
export const UPDATE_APPLICATION_SUCCESS_MESSAGE = "Update application data successfull!";
export const CREATE_APPLICATION_SUCCESS_MESSAGE = "Create application data successfull!";

export const FETCH_EMAIL_CONFIGS_SUCCESS_MESSAGE = "Fetch email configs data successfull!";
export const DELETE_EMAIL_CONFIGS_SUCCESS_MESSAGE = "Delete email configs data successfull!";
export const UPDATE_EMAIL_CONFIGS_SUCCESS_MESSAGE = "Update email configs data successfull!";
export const CREATE_EMAIL_CONFIGS_SUCCESS_MESSAGE = "Create email configs data successfull!";


export const FETCH_REQUESTS_SUCCESS_MESSAGE = "Fetch requests data successfull!";
export const DELETE_REQUESTS_SUCCESS_MESSAGE = "Delete requests data successfull!";
export const UPDATE_REQUESTS_SUCCESS_MESSAGE = "Update requests data successfull!";

export const FETCH_DASHBOARD_SUCCESS_MESSAGE = "Fetch dashboard data successfull!";
export const DELETE_DASHBOARD_SUCCESS_MESSAGE = "Delete dashboard data successfull!";
export const UPDATE_DASHBOARD_SUCCESS_MESSAGE = "Update dashboard data successfull!";


export const RESEND_SUCCESS_MESSAGE = "Resend data successfull!";