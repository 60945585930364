import http from "../constants/axiosOrders";

const getEmailTemplatePaging = (params) => {
	return http.get("/ClickApprove/EmailTemplate/search", {
		"params": params
	});
};

const getAllEmailTemplate = () => {
	return http.get("/ClickApprove/emailtemplate/getall");
};

const getEmailTemplateById = (id) => {
	return http.get(`/ClickApprove/EmailTemplate/${id}`);
};

const createEmailTemplate = data => {
	return http.post("/ClickApprove/EmailTemplate", data);
}
const updateEmailTemplate = (data) => {
	return http.put(`/ClickApprove/EmailTemplate`, data);
}

const deleteEmailTemplate = id => {
	return http.delete(`/ClickApprove/EmailTemplate/${id}`);
}

export default {
	getEmailTemplatePaging,
	getEmailTemplateById, 
	createEmailTemplate,
	updateEmailTemplate,
	deleteEmailTemplate,
	getAllEmailTemplate,
}