/**
 * PAGE LOADING
*/
export const LOADING_STATUS = 'LOADING_STATUS';
/**
 * CREATE
*/
export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_FAILURE = 'CREATE_APPLICATION_FAILURE';
export const CREATE_CA_APPLICATION_SUCCESS = 'CREATE_CA_APPLICATION_SUCCESS';
export const CREATE_CA_APPLICATION_FAILURE = 'CREATE_CA_APPLICATION_FAILURE';
export const CREATE_CA_EMAIL_TEMPLATE_SUCCESS = 'CREATE_CA_EMAIL_TEMPLATE_SUCCESS';
export const CREATE_CA_EMAIL_TEMPLATE_FAILURE = 'CREATE_CA_EMAIL_TEMPLATE_FAILURE';
export const CREATE_ACCESS_KEY_SUCCESS = 'CREATE_ACCESS_KEY_SUCCESS';

/**
 * RETRIEVE
*/
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAILURE = 'GET_APPLICATIONS_FAILURE';
export const SET_APPLICATION = 'SET_APPLICATION';
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";
export const GET_REQUESTS = 'GET_REQUESTS';
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS';
export const GET_REQUESTS_FAILURE = 'GET_REQUESTS_FAILURE';
// CA-Application
export const GET_CA_APPLICATIONS_SUCCESS = 'GET_CA_APPLICATIONS_SUCCESS';
// FS-Application
export const GET_FS_APPLICATIONS_SUCCESS = 'GET_FS_APPLICATIONS_SUCCESS';
// CA-Email-Template
export const GET_CA_EMAIL_TEMPLATE_SUCCESS = 'GET_CA_EMAIL_TEMPLATE_SUCCESS';
export const GET_CA_ALL_EMAIL_TEMPLATE_SUCCESS = 'GET_CA_ALL_EMAIL_TEMPLATE_SUCCESS';
// Roles
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';

// Users
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export const GET_REQUEST = 'GET_REQUEST';
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS';
export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';
/**
 * UPDATE
*/
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_CA_APPLICATION_SUCCESS = 'UPDATE_CA_APPLICATION_SUCCESS';
export const UPDATE_CA_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_CA_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_APPLICATION_FAILURE = 'UPDATE_APPLICATION_FAILURE';
export const UPDATE_CA_APPLICATION_FAILURE = 'UPDATE_CA_APPLICATION_FAILURE';
export const UPDATE_CA_EMAIL_TEMPLATE_FAILURE = 'UPDATE_CA_EMAIL_TEMPLATE_FAILURE';
export const UPDATE_ACCESS_KEY_SUCCESS = 'UPDATE_ACCESS_KEY_SUCCESS';

/**
 * DELETE
*/
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
export const DELETE_APPLICATION_FAILURE = 'DELETE_APPLICATION_FAILURE';
export const DELETE_EMAIL_TEMPLATE_FAILURE = 'DELETE_EMAIL_TEMPLATE_FAILURE';
export const DELETE_ACCESS_KEY_SUCCESS = 'DELETE_ACCESS_KEY_SUCCESS';
export const FETCH_INGREDIENTS_FAILED = 'FETCH_INGREDIENTS_FAILED';
export const SET_APPLICATION_MODAL = 'SET_APPLICATION_MODAL';
export const SET_EMAIL_TEMPLATE_MODAL = 'SET_EMAIL_TEMPLATE_MODAL';

/**
 * CREATE & UPDATE MAILCONFIG
*/

export const CREATE_UPDATE_MAILCONFIG = 'CREATE_UPDATE_MAILCONFIG';
export const CREATE_UPDATE_MAILCONFIG_SUCCESS = 'CREATE_UPDATE_MAILCONFIG_SUCCESS';
export const CREATE_UPDATE_MAILCONFIG_FAILURE = 'CREATE_UPDATE_MAILCONFIG_FAILURE';

/**
 * DELETE MAILCONFIG
*/
export const DELETE_MAILCONFIG = 'DELETE_MAILCONFIG';
export const DELETE_MAILCONFIG_SUCCESS = 'DELETE_MAILCONFIG_SUCCESS';
export const DELETE_MAILCONFIG_FAILURE = 'DELETE_MAILCONFIG_FAILURE';
export const SET_MAILCONFIG_MODAL = 'SET_MAILCONFIG_MODAL';

/**
 * SEND EMAIL
*/
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';


/**
 * TOAST
*/
export const SEND_TOAST = 'SEND_TOAST';

/**
 * SERVER API KEY
*/
export const CHECK_SERVER_API_KEY_SUCCESS = 'CHECK_SERVER_API_KEY_SUCCESS';
export const RESET_SERVER_API_KEY_MESSAGE = 'RESET_SERVER_API_KEY_MESSAGE';

/**
 * Response NS endpoint do not exist
*/
export const RESPONESE_ENDPOINT_NOT_EXIST = '<h1>Notification Service API is running...</h1>'
export const MS_ERRO_ENDPOINT_NOT_EXIST = 'Endpoint is not exist!'


