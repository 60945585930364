import React, { useState } from "react";
import ReactSummernote from '@humanscape/react-summernote-bs4';
import "@humanscape/react-summernote-bs4/dist/react-summernote.css"; // import styles
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import { Row, Col } from "react-bootstrap";

function CAEmailTemplateTextEditorContent({
  onChangeContent,
  modalData,
  errors,
  ...props
}) {
  const [content, setContent] = useState(modalData.content);
  const [amendmentButton, setAmendmentButton] = useState(modalData.amendmentButton);
  const [approveButton, setApproveButton] = useState(modalData.approveButton);
  const [rejectedButton, setRejectedButton] = useState(modalData.rejectedButton);
  const onChange = () => {
    let result = {
      ...modalData,
      content: content,
      amendmentButton: amendmentButton,
      approveButton: approveButton,
      rejectedButton: rejectedButton,
    };
    onChangeContent(result);
  }
  onChange();
  const erroMessageRender = (nameField) => {
    return (
      <>
        {(errors.length > 0 && errors.some(item => item.key === nameField)) ? <small className="form-text text-danger">{errors.find(item => item.key === nameField).message}</small> : ""}
      </>
    );
  }
  return (
    <>
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            Content <span className="required">*</span>
          </label>
        </Col>
        <Col xs={9} md={9}>
          <ReactSummernote
            defaultValue={modalData.content}
            options={{
              lang: 'en-US',
              height: 350,
              dialogsInBody: true,
              toolbar: [
                ['style', ['fontname', 'fontsize', 'fontsizeunit', 'color', 'forecolor', 'backcolor',
                  'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
                ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
                ['insert', ['link', 'table', 'hr']],
                ['misc', ['fullscreen', 'codeview', 'undo', 'redo', 'help']]
              ]
            }}
            onChange={(e) => setContent(e)}
          />
          {erroMessageRender('content')}
        </Col>
      </Row>
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            Amendment Button <span className="required">*</span>
          </label>
        </Col>
        <Col xs={9} md={9}>
          <ReactSummernote
            defaultValue={modalData.amendmentButton}
            options={{
              lang: 'en-US',
              height: 150,
              dialogsInBody: true,
              toolbar: [
                ['style', ['fontname', 'fontsize', 'fontsizeunit', 'color', 'forecolor', 'backcolor',
                  'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
                ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
                ['insert', ['link', 'table', 'hr']],
                ['misc', ['fullscreen', 'codeview', 'undo', 'redo', 'help']]
              ]
            }}
            onChange={(e) => setAmendmentButton(e)}
          />
          {erroMessageRender('amendmentButton')}
        </Col>
      </Row>
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            Approve Button <span className="required">*</span>
          </label>
        </Col>
        <Col xs={9} md={9}>
          <ReactSummernote
            defaultValue={modalData.approveButton}
            options={{
              lang: 'en-US',
              height: 150,
              dialogsInBody: true,
              toolbar: [
                ['style', ['fontname', 'fontsize', 'fontsizeunit', 'color', 'forecolor', 'backcolor',
                  'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
                ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
                ['insert', ['link', 'table', 'hr']],
                ['misc', ['fullscreen', 'codeview', 'undo', 'redo', 'help']]
              ]
            }}
            onChange={(e) => setApproveButton(e)}
          />
          {erroMessageRender('approveButton')}
        </Col>
      </Row>
      <Row className="ca-row-form">
        <Col xs={3} md={3} className="text-right">
          <label className="form-label ca-form-label">
            Rejected Button <span className="required">*</span>
          </label>
        </Col>
        <Col xs={9} md={9}>
          <ReactSummernote
            defaultValue={modalData.rejectedButton}
            options={{
              lang: 'en-US',
              height: 150,
              dialogsInBody: true,
              toolbar: [
                ['style', ['fontname', 'fontsize', 'fontsizeunit', 'color', 'forecolor', 'backcolor',
                  'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
                ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
                ['insert', ['link', 'table', 'hr']],
                ['misc', ['fullscreen', 'codeview', 'undo', 'redo', 'help']]
              ]
            }}
            onChange={(e) => setRejectedButton(e)}
          />
          {erroMessageRender('rejectedButton')}
        </Col>
      </Row>
    </>
  );
}

export default CAEmailTemplateTextEditorContent;