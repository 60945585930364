import { memo, useEffect } from "react";
import React, { useState } from "react";
import * as appConstants from '../../../../../constants/appConstant';
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FaIcon from '@fortawesome/free-solid-svg-icons';

function EmailConfigForm({
  editData,
  onCancel,
  onCreateUpdateConfig,
  hashKey,
  labelAction,
  onUpdateEmailConfigs,
  emailConfigs
  }) {

  const [configData, setConfigData] = useState({
    //"user": "",
    //"password": "",
    "email": "",
    "host": "",
    "port": "",
    "ssl": false,
    "useDefaultCredential": false,
    "mode": labelAction,
    "hideContent": false,
    "previewMessage": "This memorandum is classified. Please proceed to view the details on Outlook or <URL> directly",
  });
  const [errors, setErrors] = useState([]);
  let formValid = false;

  const setInputValue = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;   
      setConfigData({
        ...configData,
        [name]: value
      })
    }
  };

  const setCheckbox = (e) => {
    if (e && e.target) {
      const { name, checked } = e.target;
      setConfigData({
        ...configData,
        [name]: checked
      });
    }
  };

  const onUpdateConfig = async () => {    
    checkValid();
    if (formValid) {
      if(!configData.hideContent) {
        configData.previewMessage = "";
      }
      await onCreateUpdateConfig(hashKey, configData);
      onUpdateEmailConfigs(configData);
      onCancel();
    }
  };

  const checkValid = () => {        
    resetErrors();
    let tempErrors = [];
    formValid = true;
    const emailPattern = appConstants.EMAIL_PATTERN;
    const numberPattern = appConstants.NUMBER_PATTERN;
    const validHostName = appConstants.HOST_NAME_PATTERN;
    
    // if (configData.user === "") {
    //   tempErrors.push({
    //     "key": "user",
    //     "message": "User is required"
    //   });
    // }
  
    // if (configData.password === "") {
    //   tempErrors.push({
    //     "key": "password",
    //     "message": "Password is required"
    //   });
    // }

    if (configData.email === "") {
      tempErrors.push({
        "key": "email",
        "message": "Email is required"
      });
    } else if (emailConfigs.some(item => item.email === configData.email) && labelAction === "Add" ) {
      tempErrors.push({
        "key": "email",
        "message": "This email already exists!"
      });
    } else if (!emailPattern.test(configData.email)) {
      tempErrors.push({
        "key": "email",
        "message": "Invalid format"
      });
    }

    if (configData.host === "") {
      tempErrors.push({
        "key": "host",
        "message": "Host is required"
      });
    } else if (!validHostName.test(configData.host)) {
      tempErrors.push({
        "key": "host",
        "message": "Invalid format"
      });
    }
    
    if (configData.port === "") {
      tempErrors.push({
        "key": "port",
        "message": "Port is required"
      });
    } else if(!numberPattern.test(configData.port)) {
      tempErrors.push({
        "key": "port",
        "message": "Invalid value"
      });
    }

    if (configData.hideContent && configData.previewMessage === "") {
      tempErrors.push({
        "key": "previewMessage",
        "message": "Preview Message is required"
      });
    }
    if (tempErrors.length > 0) formValid = false;

    setErrors(errors.concat(tempErrors));
    
  }

  const resetErrors = () => {
    while(errors.length > 0) {
      setErrors(errors.pop());
    }   
  }

  useEffect(() => {
    if(Object.keys(editData).length > 0) {
      setConfigData({
        ...configData,
        "email": editData.email ? editData.email : "",
        "host": editData.host ? editData.host : "",
        //"password": editData.password ? editData.password : "",
        "port": editData.port ? editData.port : "",
        "ssl": editData.ssl,
        "useDefaultCredential": editData.useDefaultCredential,
        //"user": editData.user ? editData.user : "",
        "hideContent": editData.hideContent,
        "previewMessage": editData.previewMessage ? editData.previewMessage : configData.previewMessage,
      })
    }
  }, []);

  const inputSelect = (nameDisplay, nameField, dataListSelect = [], isRequired) => {
    return (
      <Row>
        <Col xs={3} md={3} className="text-right">
          <label className="control-label">
            {nameDisplay} {isRequired ? <span className="required">*</span> : ""}
          </label>
        </Col>
        <Col xs={9} md={9}>
          <select value={configData[nameField]} name={nameField} className="form-control" onChange={e => setInputValue(e)}>
            {dataListSelect.map((item, index) => {
              var displayNameOption = item.value ? `${item.name}` : item.name;
              return <option key={index} value={item.value}>{displayNameOption}</option>;
            })}
          </select>
          {(errors.length > 0 && errors.some(item => item.key === "user")) ? <small className="form-text text-danger">{errors.find(item => item.key === nameField).message}</small> : ""}
        </Col>
      </Row>
    );
  }
  

  return (
    <form>
        <Container className="form-fields">
          <input type="hidden" value="something" />
          {/* <Row>
            <Col xs={3} md={3} className="text-right">
              <label className="control-label">Name<span className="required">*</span></label>
            </Col>
            <Col xs={9} md={9}>
              <input
                className="form-control"
                type="text"
                placeholder="Name"
                name="user"
                defaultValue={editData ? editData.user : null}
                onChange={setInputValue}
                maxLength="256"
              />
              {(errors.length > 0 && errors.some(item => item.key === "user")) ? <small className="form-text text-danger">{errors.find(item => item.key === "user").message}</small> : ""}
            </Col>
          </Row> */}
          {/* <Row>
            <Col xs={3} md={3} className="text-right">
            <label className="control-label">Password<span className="required">*</span></label>
            </Col>
            <Col xs={9} md={9}>
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                name="password"
                defaultValue={editData ? editData.password : null}
                onChange={setInputValue}
                maxLength="256"
              />
              {(errors.length > 0 && errors.some(item => item.key === "password")) ? <small className="form-text text-danger">{errors.find(item => item.key === "password").message}</small> : ""}
            </Col>
          </Row> */}
          {labelAction === 'Add' ? (
            <Row>
                <Col xs={3} md={3} className="text-right">
                  <label className="form-label">Email<span className="required">*</span></label>
                </Col>
                <Col xs={9} md={9}>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    name="email"
                    defaultValue={editData ? editData.email : null}
                    onChange={setInputValue}
                    maxLength="100"
                  />
                {(errors.length > 0 && errors.some(item => item.key === "email")) ? <small className="form-text text-danger">{errors.find(item => item.key === "email").message}</small> : ""}
                </Col>
            </Row>
          ) : null}
          {labelAction === 'Update' ? (
            <Row>
                <Col xs={3} md={3} className="text-right">
                  <label className="form-label">Email<span className="required">*</span></label>
                </Col>
                <Col xs={9} md={9}>
                  <label className="form-label text-muted">{editData.email}</label>
                </Col>
            </Row>
          ) : null}
          <Row>
            <Col xs={3} md={3} className="text-right">
              <label className="control-label">Host<span className="required">*</span></label>
            </Col>
            <Col xs={9} md={9}>
              <input
                className="form-control"
                type="text"
                placeholder="Host"
                name="host"
                defaultValue={editData ? editData.host : null}
                onChange={setInputValue}
                maxLength="256"
              />
            {(errors.length > 0 && errors.some(item => item.key === "host")) ? <small className="form-text text-danger">{errors.find(item => item.key === "host").message}</small> : ""}
            </Col>
          </Row>
          <Row>
            <Col xs={3} md={3} className="text-right">
              <label className="control-label">Port<span className="required">*</span></label>
            </Col>
            <Col xs={9} md={9}>
              <input
                className="form-control"
                type="text"
                placeholder="Port"
                name="port"
                defaultValue={editData ? editData.port : null}
                onChange={setInputValue}
                maxLength="9"
              />
              {(errors.length > 0 && errors.some(item => item.key === "port")) ? <small className="form-text text-danger">{errors.find(item => item.key === "port").message}</small> : ""}
            </Col>
          </Row>
          <Row>
            <Col xs={3} md={3} className="text-right">
              <label className="control-label">Use SSL</label>
            </Col>
            <Col xs={9} md={9}>
              <input
                type="checkbox"
                name="ssl"
                defaultChecked={editData ? editData.ssl : false}
                onChange={setCheckbox}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3} md={3} className="text-right">
              <label className="control-label">Use Default Credential</label>
            </Col>
            <Col xs={9} md={9}>
              <input
                type="checkbox"
                name="useDefaultCredential"
                defaultChecked={
                  editData ? editData.useDefaultCredential : false
                }
                onChange={setCheckbox}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3} md={3} className="text-right">
            <FontAwesomeIcon icon={FaIcon.faExclamationCircle} title="If Hide Content is 'Enabled', recipients will only see below Preview Message instead of showing the actual body content in notification hub"/>&nbsp;<label className="control-label">Hide Content</label>
            </Col>
            <Col xs={9} md={9}>
              <input
                type="checkbox"
                name="hideContent"
                defaultChecked={
                  editData ? editData.hideContent : false
                }
                onChange={setCheckbox}
              />
            </Col>
          </Row>
          {configData.hideContent ? (
            <Row>
                <Col xs={3} md={3} className="text-right">
                <label className="form-label">Preview Message<span className="required">*</span></label>
                </Col>
                <Col xs={9} md={9}>
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Preview Message" 
                    name="previewMessage"
                    defaultValue={editData.previewMessage ? editData.previewMessage : configData.previewMessage}
                    onChange={setInputValue}
                  />
                {(errors.length > 0 && errors.some(item => item.key === "previewMessage")) ? <small className="form-text text-danger">{errors.find(item => item.key === "previewMessage").message}</small> : ""}
                </Col>
            </Row>
          ) : null}
          <div className="text-right">
            <Button variant="secondary" className="mr-1" onClick={onCancel} >Cancel</Button>
            <Button onClick={() => {onUpdateConfig() }}>{labelAction}</Button>
          </div>
        </Container>
    </form>
  );
}
export default memo(EmailConfigForm);
