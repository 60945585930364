import React, { memo, useEffect } from "react";
import RequestByDate from "./RequestByDate";
import RequestCounting from "./RequestCounting";
import Rates from "./Rates";
import PageHeader from "../../../components/PageHeader";
import DatetimeFilter from "./DatetimeFilter";
import Loading from '../../../components/Loading';
import { Row } from "react-bootstrap";
function Wrapper(props) {

  
  useEffect(() => {
    props.onInitDashboardData();    
  }, []);

  return (
    <React.Fragment>
      <div className="page">
        <PageHeader
            externalHeaderActions={
              <DatetimeFilter {...props} />
            }
            pageTitle={"Dashboard"}
        />
        <div className="page__body">        
          <RequestCounting {...props} />
          <Rates {...props} />
          <RequestByDate {...props} />
        </div>
      </div>
     {/* {
       props.loading ? (
        <Loading />
       ) : ""
     } */}
    </React.Fragment>
  );
}

export default memo(Wrapper);
