import React, { memo, useEffect, useState } from "react";
import RequestsList from "./RequestsList";
import RequestsFilter from "./RequestsFilter";
import PageHeader from "../../../components/PageHeader";
import Pagination from "react-js-pagination";
import * as appConstant from '../../../constants/appConstant';


function Wrapper(props) {
  const [filterParamsData, setFilterParamsData] = useState({});
  const [activePage, setActivePage] = useState(1);



  const setFilter = (params) => {
    setFilterParamsData(params);
  }

  const onPageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const pageIndex = pageNumber - 1;
    const draft = filterParamsData;
    draft.pageIndex = pageIndex;
    props.onInitRequests(draft)
  }


  const renderTotalList = () => {
    let emailRequestDetails = props.requestsData.emailRequestDetails ? props.requestsData.emailRequestDetails : [];
    return (
      <p style={{ display: 'block', fontSize: 14 }}>Showing: {(activePage - 1) * appConstant.GLOBAL_PAGE_SIZE} to {emailRequestDetails.length > (activePage * appConstant.GLOBAL_PAGE_SIZE) ? activePage * appConstant.GLOBAL_PAGE_SIZE : (activePage - 1) * appConstant.GLOBAL_PAGE_SIZE + emailRequestDetails.length} of first {props.requestsData.totalRequests} records (may have more)</p>
    );
  }
  
  return (
    <React.Fragment>
      <PageHeader
        externalHeaderActions={
          <RequestsFilter
            onSetActivePage={(pageId) => setActivePage(pageId)}
            setFilterParamsData={(params) => { setFilter(params) }} {...props}
          />}
        pageTitle={"Requests"}
      />
      <RequestsList
        setFilterParamsData={(params) => { setFilter(params) }}
        filterParamsData={filterParamsData}
        {...props}
      />

      {props.requestsData ? (
        <div className="pagination-container" style={{ flexDirection: 'column' }}>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={activePage}
            itemsCountPerPage={appConstant.GLOBAL_PAGE_SIZE}
            totalItemsCount={props.requestsData.totalRequests}
            onChange={pageNumber => onPageChange(pageNumber)}
          />
          {renderTotalList()}
        </div>
      ) : null}

    </React.Fragment>
  );
}

export default memo(Wrapper);
