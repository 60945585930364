import * as actionTypes from "../../constants/actionTypes";

export const initialState = {
  dashboardData: null,
  error: false,
  loading: false
};

export const dashboardDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: action.dashboard
      }
    case actionTypes.LOADING_STATUS:
      return {
        ...state,       
        loading: action.loading,   
      }
    default:
      return state;
  }
};

export default dashboardDataReducer;
