/* eslint-disable no-unused-vars */
import React from "react";
import { Modal} from "react-bootstrap";
import "./styles.scss";
import ApplicationForm from './ApplicationForm';


function ApplicationModal({
  modalShow,
  onCreateData,
  onUpdateData,
  modalData,
  modalMode,
  onShowEditModal,
  onSetEditData,
  onCloseModal,
  ...props
}) {

  const modalTitle = {
    "EDIT": "Edit Application",
    "ADD": "Add Application",
  };
  
  
  return (
    <Modal
      show={modalShow}
      onHide={() => onShowEditModal(false)}
      size="xl"
      centered
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">{`${modalTitle[modalMode]}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>      
        {modalData ? (
          <ApplicationForm 
            onCloseModal={() => onCloseModal()}
            modalData={modalData}
            onCreateData={() => onCreateData()}
            onUpdateData={() => onUpdateData()}
            onSetEditData={(data) => onSetEditData(data)}
            {...props}
            />
        ) : null}
      </Modal.Body>
    </Modal>
  );
}
export default ApplicationModal;
