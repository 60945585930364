import * as actionTypes from "../../constants/actionTypes";
import * as appContants from "../../constants/appConstant";
import services from "../../services/applicationService";
import {updateLoadingStatus} from './app';
import { toast } from "react-toastify";

export const fetchApplicationsSuccess = (applications) => {
  return {
    type: actionTypes.GET_APPLICATIONS_SUCCESS,
    applications: applications,
  };
};
export const fetchApplicationsFailure = (error) => {
  return {
    type: actionTypes.GET_APPLICATIONS_FAILURE,
    error,
  };
};
export const updateApplicationSuccess = (applications) => {
  return {
    type: actionTypes.UPDATE_APPLICATION_SUCCESS,
    applications: applications,
  };
};
export const updateApplicationFailure = (error) => {
  return {
    type: actionTypes.UPDATE_APPLICATION_FAILURE,
    error,
  };
};
export const deleteApplicationSuccess = (applications) => {
  return {
    type: actionTypes.DELETE_APPLICATION_SUCCESS,
    applications,
  };
};
export const deleteApplicationFailure = (error) => {
  return {
    type: actionTypes.DELETE_APPLICATION_FAILURE,
    error,
  };
};
export const createApplicationSuccess = (applications) => {
  return {
    type: actionTypes.CREATE_APPLICATION_SUCCESS,
    applications,
  };
};
export const createApplicationFailure = (error) => {
  return {
    type: actionTypes.CREATE_APPLICATION_FAILURE,
    error,
  };
};

export const fetchApplicationFailed = () => {
  return {
    type: actionTypes.FETCH_INGREDIENTS_FAILED,
  };
};

export const setModalStatus = (status) => {
  return {
    type: actionTypes.SET_APPLICATION_MODAL,
    status,
  };
};

export const fetchMailConfigSuccess = (mailConfigs) => {
  return {
    type: actionTypes.CREATE_UPDATE_MAILCONFIG_SUCCESS,
    mailConfigs,
  };
};

export const fetchMailConfigFailure = (error) => {
  return {
    type: actionTypes.CREATE_UPDATE_MAILCONFIG_FAILURE,
    error,
  };
};

export const deleteMailConfigFailure = (error) => {
  return {
    type: actionTypes.DELETE_MAILCONFIG_FAILURE,
    error,
  };
};

export const createAccessKeySuccess = (accessKey) => {
  return {
    type: actionTypes.CREATE_ACCESS_KEY_SUCCESS,
    accessKey
  };
};

export const checkServerApiKeySuccess = (apiKey) => {
  return {
    type: actionTypes.CHECK_SERVER_API_KEY_SUCCESS,
    apiKey
  };
};


export const resetServerApiKeyMessage = () => {
  return {
    type: actionTypes.RESET_SERVER_API_KEY_MESSAGE    
  };
};




export const fetchApplications = (params) => {
  console.log('fetchApplications');
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .getApplicationList()
      .then((response) => {
        dispatch(fetchApplicationsSuccess(response.data)); 
      })
      .catch((error) => {        
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const updateApplication = (params) => {
  return (dispatch) => {
    const { hashKey } = params;
    dispatch(updateLoadingStatus(true));
    services
      .updateApplication(hashKey, params)
      .then((response) => {        
        toast.success(appContants.UPDATE_SUCCESS_MESSAGE, {toastId: "update-success"});
        dispatch(fetchApplications());
      })
      .catch((error) => {   
        // toast.error(error.toString());
        let errorMsg = error.toString(); 
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const deleteApplication = (id) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .deleteApplication(id)
      .then((response) => {
        toast.success(appContants.DELETE_SUCCESS_MESSAGE, {toastId: "delete-success"});
        dispatch(fetchApplications());
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const createApplication = (data) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .createApplication({ ...data })
      .then((response) => {  
        toast.success(appContants.CREATE_SUCCESS_MESSAGE, {toastId: "create-success"});
        dispatch(fetchApplications());
      })
      .catch((error) => {
        let errorMsg = error.toString(); 
        if (error.response.data) {
          errorMsg = error.response.data.response.message
        }
        toast.error(errorMsg);
      })
      .finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const fecthMailConfig = (id) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .getApplicationList()
      .then((response) => {
        const selectData = response.data.find((item) => item.hashKey === id);
        const mailConfigs = selectData.mailConfigs;
        dispatch(fetchMailConfigSuccess(mailConfigs));
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};
export const createAndUpdateMailConfig = (id, data) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .createUpdateMailConfig(id, data)
      .then((response) => {
        if (response.status === 200) {
          if (data.mode === 'Add') toast.success(appContants.CREATE_SUCCESS_MESSAGE, {toastId: "create-success"});
          if (data.mode === 'Update') toast.success(appContants.UPDATE_SUCCESS_MESSAGE, {toastId: "update-success"});         
          dispatch(fecthMailConfig(id));
          dispatch(fetchApplications());
        }
        else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {        
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const createAndUpdateMailConfigFailure = (error) => {
  return {
    type: actionTypes.CREATE_UPDATE_MAILCONFIG_FAILURE,
    error,
  };
};

export const deleteMailConfig = (id, params) => {
  return (dispatch) => {
    dispatch(updateLoadingStatus(true));
    services
      .deleteMailConfig(id, params)
      .then((response) => {
        const { status, statusText } = response;
        if (status === 200 && statusText === "OK") {
          dispatch(fecthMailConfig(id));
          toast.success(appContants.DELETE_SUCCESS_MESSAGE);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  };
};

export const createAccessKey = (params) => {
  return (dispatch) => {    
    dispatch(updateLoadingStatus(true));
    services
      .createAccessKey(params)
      .then(response => {        
        dispatch(createAccessKeySuccess(response));
        toast.success(appContants.CREATE_SUCCESS_MESSAGE);
        dispatch(fetchApplications());
      })
      .catch(error => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  }
}

export const deleteAccessKey = (id, keyId) => {
  return (dispatch) => {    
    dispatch(updateLoadingStatus(true));
    services
      .deleteAccessKey(id, keyId)
      .then(response => {
        toast.success(appContants.DELETE_SUCCESS_MESSAGE, {toastId: "delete-success"});
      })
      .catch(error => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  }
}

export const updateAccessKey = (id, params) => {
  return (dispatch) => {    
    dispatch(updateLoadingStatus(true));
    services
      .updateAccessKey(id, params)
      .then(response => {
        toast.success(appContants.UPDATE_SUCCESS_MESSAGE, {toastId: "update-success"});
      })
      .catch(error => {
        toast.error(error.toString());
      }).finally(() => {
        dispatch(updateLoadingStatus(false));
      });
  }
}


export const checkApiKey = (apiKey) => {
  return (dispatch) => {       
    services
      .checkServerApiKey(apiKey)
      .then(response => {
        dispatch(checkServerApiKeySuccess(response.data.message));
      })
      .catch(error => {        
        //error.response.data.message\
        let message = error.response.data.response.message;
        if (error.response.data.message)  {
          message = error.response.data.message;          
        }
        dispatch(checkServerApiKeySuccess(message))
      })
      .finally();
  }
}
