import React from "react";
import { connect } from "react-redux";
import Wrapper from "./Wrapper";
import * as actions from "../../store/actions/requests";
import * as actionsApplication from "../../store/actions/application";
import "./styles.scss";

function CARequests(props) {
  return (
    <React.Fragment>
      <Wrapper {...props} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    requestsData: state.requests.requestsData,
    applicationsData: state.application.applications,
    loading: state.requests.loading,
    request: {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitRequests: (params) => dispatch(actions.fetchCARequests(params)),   
    onInitApplication: (params) => dispatch(actions.fetchCAApplications(params)),
    onResendEmail: (id, params) => dispatch(actions.resendEmail(id, params)),
    onGetRequestDetail: (id) => dispatch(actions.getRequestDetail(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CARequests);
