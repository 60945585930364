import axios from "axios";
import {configuration } from './configs';



const authHeader = () => {
	//return authorizaiton header with jwt token
	let user = JSON.parse(localStorage.getItem('user'));

	if (user && user.access_token) {
		return { 'Authorization': 'Bearer ' + user.access_token }
	} else {
		return {};
	}
}

const http = axios.create({
	baseURL: configuration.baseUrlFSApi
})
http.defaults.headers = authHeader();

export default http;