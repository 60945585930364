import React, { useState } from "react";
import Button from 'react-bootstrap/Button';

function CAApplicationFilter({onSetActivePage, setFilterParamsData, filterParamsData,...props }) {
    const [filterName, setFilterName] = useState();

    const onChangeSearchFilterName = (e) =>{
        setFilterName(e.target.value)
    };

    const onFilter = async () => {
        const mockData = {};
        if(filterName) mockData.name = filterName ? filterName.trim() : '';
        mockData.limits = filterParamsData.limits;
        mockData.pageIndex = 0;
        await props.onInitApplications(mockData);
        onSetActivePage(1);
        setFilterParamsData(mockData)
      }

    return (
        <React.Fragment>
            <div className="form-filter">
                <div className="form-filter__item mr-2">
                    <span className="form-filter__controls">
                        <input className="form-filter__form-input form-control" maxLength="50" onChange={e => onChangeSearchFilterName(e)} placeholder="Filter by name" />
                    </span>
                </div>

                <Button onClick={() => onFilter()} variant="primary">
                    Search
                </Button>
            </div>
        </React.Fragment>
    );
}

export default CAApplicationFilter;