
import React from 'react';
import App from './App';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { aadConfig } from "./constants/aadConfig";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(aadConfig);

 // Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  
  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();
  
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

const AppWithAuthAAD = () => (
    <App pca={msalInstance}/>
)

export default AppWithAuthAAD;